<template>
  <el-main>
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{
          $t("onlineRecharge.home")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          $t("onlineRecharge.depositApplication")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          $t("onlineRecharge.title")
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="18">
        <div class="grid-content bg-purple" style="border-radius: 10px">
          <el-tabs type="border-card" style="border-radius: 10px; height: 820px">
            <el-tab-pane :label="$t('onlineRecharge.depositInUsd')">
              <div class="user_box" style="margin-top: 65px">
                <el-form :model="form_hw" label-width="120px">
                  <!-- 充值手续费 -->
                  <!-- 汇付 -->
                  <el-form-item v-show="zfTypes == 1" prop="proportion" >
                    <template slot="label">
                      <div>{{ $t('onlineRecharge.depositFee') }}</div>
                    </template>
                      <el-input style="width: 100%" v-model="form_hw.usdlv" disabled prefix-icon="el-icon-coin">
                    </el-input>
                    
                    <!-- <div class="xts" style="padding-left: 45px">
                      <img class="tb_img" src="../../images/xhj.png" />
                      {{ $t("onlineRecharge.zfbdepositFeedes") }}
                    </div> -->
                  </el-form-item>
                  <!-- 泰达币充值手续费 -->
                  <!-- <el-form-item v-show="zfTypes == 6 " prop="proportion" :label="$t('onlineRecharge.depositFee')">
                    <el-input style="width: 100%" v-model="form_usdt.service_char" disabled prefix-icon="el-icon-coin">
                    </el-input>
                    <div class="xts" style="padding-left: 45px">
                      <img class="tb_img" src="../../images/xhj.png" />
                      {{ $t("onlineRecharge.zfbdepositFeedes") }}
                    </div>
                  </el-form-item> -->
                  <!-- <el-form-item v-show="zfTypes == 3" prop="proportion" :label="$t('onlineRecharge.depositFee')">
                    <el-input style="width: 100%" v-model="form_hw.service_char" disabled prefix-icon="el-icon-coin">
                    </el-input>
                    <div class="xts" style="padding-left: 45px">
                      <img class="tb_img" src="../../images/xhj.png" />
                      {{ $t("onlineRecharge.wxdepositFeedes") }}
                    </div>
                  </el-form-item> -->
                  <!-- <el-form-item v-show="zfTypes == 4" prop="proportion" :label="$t('onlineRecharge.depositFee')">
                    <el-input style="width: 100%" v-model="form_hw.service_char" disabled prefix-icon="el-icon-coin">
                    </el-input>
                    <div class="xts" style="padding-left: 45px">
                      <img class="tb_img" src="../../images/xhj.png" />
                      {{ $t("onlineRecharge.zfbdepositFeedes") }}
                    </div>
                  </el-form-item> -->
                  <!-- 香港对公手续费 -->
                  <!-- <el-form-item v-show="zfTypes == 5" prop="proportion" :label="$t('onlineRecharge.depositFee')">
                    <el-input style="width: 100%" disabled prefix-icon="el-icon-coin">
                    </el-input>
                    <div class="xts" style="padding-left: 45px">
                      <img class="tb_img" src="../../images/xhj.png" />
                      {{ $t("onlineRecharge.zfbdepositFeedes") }}
                    </div>
                  </el-form-item> -->
                  <!-- 充值手续费 -->
                  <!-- <el-form-item v-show="zfTypes == 2" prop="proportion" :label="$t('onlineRecharge.depositFee')">
                    <el-input style="width: 100%" v-model="form_onlineRecharge.service_char" disabled
                      prefix-icon="el-icon-coin">
                    </el-input>
                    <div class="xts" style="padding-left: 45px">
                      <img class="tb_img" src="../../images/xhj.png" />{{
                        $t("onlineRecharge.wxdepositFeedes")
                      }}
                    </div>
                  </el-form-item> -->
                  <!-- zfb充值金额 -->
                  <el-form-item v-show="zfTypes == 1 || zfTypes == 3 || zfTypes == 4" prop="rechargeNums"
                    :label="$t('onlineRecharge.topUpAmount')">
                    <!-- <el-tag size="danger">当前美金汇率:{{ this.form_hw.usdlv }}</el-tag> -->
                    <el-input style="width: 100%" v-model="form_hw.rechargeNums" prefix-icon="el-icon-coin"
                      @input="getHaiAmount()" placeholder="0.00" :min="20" :max="3000">
                    </el-input>
                  </el-form-item>
                  <!-- 香港对公 -->
                  <el-form-item v-show="zfTypes == 5" prop="rechargeNums" :label="$t('onlineRecharge.topUpAmount')">
                    <!-- <el-tag size="danger">当前美金汇率:{{ this.form_hw.usdlv }}</el-tag> -->
                    <el-input style="width: 100%" prefix-icon="el-icon-coin" placeholder="0.00" :min="20" :max="3000"
                      disabled>
                    </el-input>
                  </el-form-item>
                  <!-- 泰达币充值金额 -->
                  <el-form-item v-show="zfTypes == '6'" prop="rechargeNums" :label="$t('onlineRecharge.topUpAmount')">
                    <!-- <el-tag size="danger">当前美金汇率:{{ this.form_hw.usdlv }}</el-tag> -->
                    <el-input style="width: 100%" v-model="form_usdt.rechargeNums" prefix-icon="el-icon-coin"
                      @input="getUsdtAmount()" placeholder="0.00" :min="20" :max="3000">
                    </el-input>
                  </el-form-item>
                  <!-- wx充值金额 -->
                  <el-form-item v-show="zfTypes == 2" prop="rechargeNums" :label="$t('onlineRecharge.topUpAmount')">
                    <!-- <el-tag v-if="CurrencyType == '2'" size="danger">当前美金汇率:{{ usdlv }}</el-tag> -->
                    <el-input style="width: 100%" v-model="form_onlineRecharge.rechargeNums" prefix-icon="el-icon-coin"
                      @change="getAmount()" placeholder="0.00" :min="100" :max="2000">
                    </el-input>
                  </el-form-item>
                  <!-- 充值方式 -->
                  <!-- 汇付 -->
                  <el-form-item prop="zfTypes" :label="$t('onlineRecharge.depositMethod')">
                    <el-radio v-model="zfTypes" label="1" border>
                      <img height="20" src="../../images/zfb.png" />
                      <span
                        style="color: #725cda">
                        {{ $t("onlineRecharge.crossBorderTopUp") }}</span>
                      <!-- <div class="xts">
                        {{ $t("onlineRecharge.timelyArrival") }}
                      </div> -->
                    </el-radio>

                    <!-- <el-radio v-model="zfTypes" label="2" border><img height="20" 
                        src="../../images/wx.png"><span style="color: #725CDA;">国内充值</span>
                      <div class="xts">及时到账</div>
                    </el-radio> -->
                    <!-- <el-radio v-model="zfTypes" label="3" border
                      ><img height="20" src="../../images/wx.png" /><span
                        style="color: #725cda"
                        >跨境充值</span
                      >
                      <div class="xts">及时到账</div>
                    </el-radio>
                    <el-radio v-model="zfTypes" label="4" border
                      ><img height="20" src="../../images/zfb.png" /><span
                        style="color: #725cda"
                        >跨境充值</span
                      >
                      <div class="xts">及时到账</div>
                    </el-radio> -->
                    <!-- 香港对公 -->
                    <el-radio label="5" border disabled><img height="20" src="../../images/xgdg.png" /><span
                        style="color: #725cda">{{ $t("onlineRecharge.hongKongForBusiness") }}</span>
                      <!-- <div class="xts">
                        {{ $t("onlineRecharge.timelyArrival") }}
                      </div> -->
                    </el-radio>
                    <!-- 泰达币充值 -->
                    <el-radio v-model="zfTypes" v-if="limit_utrecharge == '2' && !isChina" label="6" border><img
                        height="20" src="../../images/tdb.png" /><span style="color: #725cda">{{
                          $t("onlineRecharge.ustdRecharge")
                        }}</span>
                      <!-- <div class="xts">
                        {{ $t("onlineRecharge.timelyArrival") }}
                      </div> -->
                    </el-radio>
                  </el-form-item>
                  <!-- zfb充值类型 -->
                  <!-- <el-form-item v-show="zfTypes == 1 ||
                    zfTypes == 3 ||
                    zfTypes == 4 ||
                    zfTypes == 6 ||
                    zfTypes == 5
                    " prop="Hai_rd" :label="$t('onlineRecharge.depositCurrency')">
                    <el-radio style="margin-left: 0px" v-model="Hai_rd" label="2" border><img height="20"
                        src="../../images/usd.png" />{{
                          $t("onlineRecharge.usd")
                        }}
                    </el-radio>
                  </el-form-item> -->
                  <!-- wx充值类型 -->
                  <el-form-item v-show="zfTypes == 2" prop="CurrencyType" :label="$t('onlineRecharge.depositCurrency')">
                    <el-radio v-if="usdisok == '1'" style="margin-left: 0px" @click.native="getUsds($event)"
                      v-model="CurrencyType" label="2" border><img height="20" src="../../images/usd.png" />{{
                        $t("onlineRecharge.usd")
                      }}</el-radio>
                    <el-radio v-else-if="usdisok == '2'" style="margin-left: 0px" disabled v-model="CurrencyType"
                      label="2" border><img height="20" src="../../images/usd.png" />{{
                        $t("onlineRecharge.usd")
                      }}</el-radio>
                  </el-form-item>
                  <!-- 实际到账金额 -->
                  <el-divider></el-divider>
                  <el-form-item :label="$t('onlineRecharge.theActualAmountReceived')">
                    <span class="dzjes" v-show="zfTypes == 1 || zfTypes == 3 || zfTypes == 4">$ {{ form_hw.Amount }}
                      USD</span>
                    <span class="dzjes" v-show="zfTypes == 2">$ {{ form_onlineRecharge.Amount }} USD</span>
                    <!-- 泰达币预计到账金额 -->
                    <span class="dzjes" v-show="zfTypes == 6">$ {{ form_usdt.Amount }} USD</span>
                    <!-- 香港对公到账金额 -->
                    <span class="dzjes" v-show="zfTypes == 5">$ 0.00 USD</span>
                    <el-tooltip  popper-class="tooltip-width"   :content="$t('onlineRecharge.depositFeeTips')"
                      placement="top-start">
                      <i class="el-icon-warning" style="cursor: pointer;margin-left: 12px;"></i>
                    </el-tooltip>
                  </el-form-item>
                  <!-- <el-form-item v-show="zfTypes == 1" prop="Amount">
                    <el-checkbox v-show="zfTypes == 1" size="medium" v-model="iskjds">仅跨境电商、广告客户使用</el-checkbox>
                  </el-form-item> -->

                  <br />
                  <!-- <br /> -->
                  <!-- <el-form-item>
                    <input type="checkbox" />
                    <span>我已阅读并同意 《个人信息共享知情同意书》</span>
                  </el-form-item> -->
                  <!-- 汇付支付提交订单 -->
                  <el-form-item v-show="zfTypes == 1">
                    <el-button v-if="isthirdusd == '1'" v-no-more-click @click="subHaiRechange()">
                      {{ btntxt }}
                    </el-button>
                    <el-button v-if="isthirdusd == '2'" class="is-disabled" type="info">{{
                      $t("onlineRecharge.notAtThisTime") }}</el-button>
                  </el-form-item>
                  <!-- 泰达币充值提交订单 -->
                  <el-form-item v-show="zfTypes == '6'">
                    <el-button v-no-more-click @click="usdtRechange()">
                      {{ btntxt }}
                    </el-button>
                  </el-form-item>
                  <!-- 香港对公提交订单 -->
                  <el-form-item v-show="zfTypes == '5'">
                    <el-button class="is-disabled" type="info">{{
                      $t("onlineRecharge.notAtThisTime") }}</el-button>
                  </el-form-item>
                  <!-- 微信支付提交订单 -->
                  <el-form-item v-show="zfTypes == 2">
                    <!-- <el-button v-if="CurrencyType == '1' && rmbisok =='1'" v-no-more-click
                      @click="subOnlineRecharge()">{{ btntxt }}</el-button> -->
                    <!-- <el-button class="is-disabled" type="info" v-if="CurrencyType == '1' && rmbisok =='2'">暂不支持
                    </el-button> -->
                    <el-button v-no-more-click v-if="usdisok == '1'" @click="subUsdRecharge()">{{ btntxt
                      }}</el-button>
                    <el-button class="is-disabled" type="info" v-if="usdisok == '2'">{{
                      $t("onlineRecharge.notAtThisTime") }}
                    </el-button>
                  </el-form-item>
                  <!-- 美金充值提交订单 -->
                  <el-form-item v-show="zfTypes == 3">
                    <el-button v-if="rmbisok == '1'" v-no-more-click @click="subFXRechange('wechat')">
                      {{ btntxt }}
                    </el-button>
                    <el-button v-if="rmbisok == '2'" class="is-disabled" type="info">{{
                      $t("onlineRecharge.notAtThisTime") }}</el-button>
                  </el-form-item>
                  <!-- 支付宝复星 -->
                  <el-form-item v-show="zfTypes == 4">
                    <el-button v-if="usdisok == '1'" v-no-more-click @click="subFXRechange('alipay')">
                      {{ btntxt }}
                    </el-button>
                    <el-button v-if="usdisok == '2'" class="is-disabled" type="info">{{
                      $t("onlineRecharge.notAtThisTime") }}</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </el-tab-pane>

           
          </el-tabs>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple" style="border-radius: 10px">
          <div class="zcje">
            <div style="display: flex; justify-content: space-between">
              <h1 style="
                  font-size: 16px;
                  font-weight: 600;
                  color: var(--mainText);
                  line-height: 22px;
                ">
                {{ $t("onlineRecharge.assetOverview") }}
              </h1>
              <a @click="jyjl()" style="
                  font-size: 16px;
                  text-decoration: none;
                  color: #4458fe;
                  line-height: 52px;
                ">{{ $t("onlineRecharge.rechargeRecord") }} ></a>
            </div>
            <div style="display: flex; justify-content: space-between">
              <div style="display: flex; flex-direction: column">
                <span style="
                    display: flex;
                    align-items: center;
                    margin-top: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    font-size: 14px;
                    color: #6a74a5;
                  ">{{ $t("onlineRecharge.assetBalances") }}</span>
                <span style="
                    font-family: Rubik;
                    font-weight: 600;
                    color: var(--pageText);
                    line-height: 41px;
                  ">$ {{ form_hw.usd }}</span>
              </div>
              <div style="
                  display: flex;
                  flex-direction: column;
                  text-align: right;
                  color: #6a74a5;
                ">
                <span style="
                    display: flex;
                    align-items: center;
                    margin-top: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    font-size: 14px;
                    color: #6a74a5;
                  ">{{ $t("onlineRecharge.availableBalance") }}&nbsp;&nbsp;
                  <el-tooltip class="item" effect="dark"  :content="$t('onlineRecharge.availableBalancedes')"
                    placement="top-start"><i class="el-icon-question"></i>
                  </el-tooltip>
                </span>
                <span style="
                    margin: 4px 0 8px 0;
                    font-size: 24px;
                    font-family: Rubik;
                    font-weight: 600;
                    color: var(--pageText);
                    line-height: 32px;
                  ">$ {{ form_hw.usd }}</span>
                <span style="
                    font-size: 14px;
                    line-height: 12px;
                    margin: 8px 0 4px 0;
                    justify-content: end;
                    color: #f56c6c;
                  ">{{ $t("onlineRecharge.freezeTheAmount") }}</span>
                <span style="
                    font-size: 14px;
                    line-height: 12px;
                    font-family: Rubik;
                    font-weight: 600;
                    color: #f56c6c;
                  ">$ {{ djje }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple" style="
            border-radius: 10px;
            padding: 10px 20px;
            height: 564px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12),
              0 0 6px 0 rgba(0, 0, 0, 0.12);
          ">
          <h1 style="
              font-size: 20px;
              font-weight: 600;
              color: #3a3f63;
              line-height: 24px;
            ">
            {{ $t("onlineRecharge.frequentlyAskedQuestions") }}
          </h1>
          <span style="font-size: 16px; color: #3a3f63; font-weight: 600">{{
            $t("onlineRecharge.des1")
          }}</span>
          <p style="font-size: 14px; color: #6a74a5">
            {{ $t("onlineRecharge.des2") }}
          </p>
        </div>
      </el-col>
    </el-row>

    <!-- 微信支付 -->
    <el-dialog custom-class="ls" :visible.sync="wxVisible" width="25%" center @close="closeTime()">
      <div v-if="zfTypes == '3'" style="
          width: 100%;
          font-size: 28px;
          text-align: center;
          margin-bottom: 20px;
        ">
        <svg t="1681238376015" class="icon" viewBox="0 0 1144 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="2699" width="300" height="300">
          <path
            d="M436.314353 632.771765c-68.517647 36.321882-78.667294-20.389647-78.667294-20.389647l-85.835294-190.524236c-33.039059-90.533647 28.581647-40.839529 28.581647-40.839529s52.856471 38.038588 93.003294 61.229176c40.086588 23.190588 85.835294 6.806588 85.835294 6.806589l561.212235-246.362353C936.899765 80.112941 765.891765 0 572.235294 0 256.180706 0 0 213.232941 0 476.310588c0 151.311059 84.811294 285.967059 216.937412 373.248l-23.792941 130.288941s-11.625412 38.038588 28.611764 20.389647c27.437176-12.047059 97.370353-55.115294 138.992941-81.347764 65.445647 21.684706 136.734118 33.731765 211.486118 33.731764 316.024471 0 572.235294-213.232941 572.235294-476.310588 0-76.197647-21.594353-148.178824-59.843764-212.028235-178.808471 102.309647-594.733176 340.118588-648.312471 368.489412z"
            fill="#43C93E" p-id="2700"></path>
        </svg>
        {{ $t("onlineRecharge.weChatPay") }}
      </div>
      <div v-if="zfTypes == '4'" style="
          width: 100%;
          font-size: 28px;
          text-align: center;
          margin-bottom: 20px;
        ">
        <svg t="1685944697467" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="2557" width="200" height="200">
          <path
            d="M230.771014 576.556522c-12.614493 9.646377-25.228986 23.744928-28.93913 42.295652-5.194203 24.486957-0.742029 55.652174 22.26087 80.13913 28.93913 28.93913 72.718841 37.101449 92.011594 38.585508 51.2 3.710145 106.110145-22.26087 147.663768-50.457971 16.324638-11.130435 43.77971-34.133333 70.492754-69.750725-59.362319-30.423188-133.565217-64.556522-212.22029-61.588406-41.553623 1.484058-70.492754 9.646377-91.269566 20.776812zM983.188406 712.347826c25.971014-61.588406 40.811594-129.113043 40.811594-200.347826 0-281.971014-230.028986-512-512-512S0 230.028986 0 512s230.028986 512 512 512c170.666667 0 321.298551-83.849275 414.794203-212.22029C838.492754 768.742029 693.797101 696.023188 604.011594 652.985507c-42.295652 48.973913-105.368116 97.205797-176.602898 117.982609-44.521739 13.356522-85.333333 18.550725-126.886957 9.646377-42.295652-8.904348-72.718841-28.197101-90.527536-47.489855-8.904348-10.388406-19.292754-22.26087-27.455073-37.843479 0.742029 0.742029 0.742029 2.226087 0.742029 2.968116 0 0-4.452174-7.42029-7.420289-19.292753-1.484058-5.936232-2.968116-11.872464-3.710145-17.808696-0.742029-4.452174-0.742029-8.904348 0-12.614493-0.742029-7.42029 0-15.582609 1.484058-23.744927 4.452174-20.776812 12.614493-43.77971 35.617391-65.298551 48.973913-48.231884 115.014493-50.457971 149.147826-50.457971 50.457971 0.742029 138.017391 22.26087 212.22029 48.973913 20.776812-43.77971 34.133333-89.785507 42.295652-121.692754H304.973913v-33.391304h158.052174v-66.782609H272.324638v-34.133333h190.701449v-66.782609c0-8.904348 2.226087-16.324638 16.324638-16.324637h74.944927v83.107246h207.026087v33.391304H554.295652v66.782609H719.768116S702.701449 494.933333 651.501449 586.202899c115.014493 40.811594 277.518841 104.626087 331.686957 126.144927z m0 0"
            fill="#06B4FD" p-id="2558"></path>
        </svg>
        {{ $t("onlineRecharge.alipayPayment") }}
      </div>
      <div style="width: 100%; margin: 0 auto; text-align: center">
        <el-tag type="warning">{{ $t("onlineRecharge.des3") }}</el-tag>
        <br />
        <br />
      </div>
      <div style="text-align: center">
        <div class="qr-code" ref="qrCodeUrl"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="wxVisible = false">{{
          $t("onlineRecharge.close")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialog_hf" width="70%">
      <iframe class="myIframe" :src="url" frameborder="0" width="100%" height="700"></iframe>
    </el-dialog>
    <!-- 微信支付弹窗 -->
    <el-dialog custom-class="ls" :visible.sync="wxVisible_jl" width="25%" center @close="closeTime()">
      <div v-if="czTypes != '6'" style="
          width: 100%;
          font-size: 28px;
          text-align: center;
          margin-bottom: 20px;
        ">
        <svg t="1681238376015" class="icon" viewBox="0 0 1144 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="2699" width="200" height="200">
          <path
            d="M436.314353 632.771765c-68.517647 36.321882-78.667294-20.389647-78.667294-20.389647l-85.835294-190.524236c-33.039059-90.533647 28.581647-40.839529 28.581647-40.839529s52.856471 38.038588 93.003294 61.229176c40.086588 23.190588 85.835294 6.806588 85.835294 6.806589l561.212235-246.362353C936.899765 80.112941 765.891765 0 572.235294 0 256.180706 0 0 213.232941 0 476.310588c0 151.311059 84.811294 285.967059 216.937412 373.248l-23.792941 130.288941s-11.625412 38.038588 28.611764 20.389647c27.437176-12.047059 97.370353-55.115294 138.992941-81.347764 65.445647 21.684706 136.734118 33.731765 211.486118 33.731764 316.024471 0 572.235294-213.232941 572.235294-476.310588 0-76.197647-21.594353-148.178824-59.843764-212.028235-178.808471 102.309647-594.733176 340.118588-648.312471 368.489412z"
            fill="#43C93E" p-id="2700"></path>
        </svg>{{ $t("onlineRecharge.weChatPay") }}
      </div>
      <div v-if="czTypes == '6'" style="
          width: 100%;
          font-size: 28px;
          text-align: center;
          margin-bottom: 20px;
        ">
        <svg t="1685944697467" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="2557" width="200" height="200">
          <path
            d="M230.771014 576.556522c-12.614493 9.646377-25.228986 23.744928-28.93913 42.295652-5.194203 24.486957-0.742029 55.652174 22.26087 80.13913 28.93913 28.93913 72.718841 37.101449 92.011594 38.585508 51.2 3.710145 106.110145-22.26087 147.663768-50.457971 16.324638-11.130435 43.77971-34.133333 70.492754-69.750725-59.362319-30.423188-133.565217-64.556522-212.22029-61.588406-41.553623 1.484058-70.492754 9.646377-91.269566 20.776812zM983.188406 712.347826c25.971014-61.588406 40.811594-129.113043 40.811594-200.347826 0-281.971014-230.028986-512-512-512S0 230.028986 0 512s230.028986 512 512 512c170.666667 0 321.298551-83.849275 414.794203-212.22029C838.492754 768.742029 693.797101 696.023188 604.011594 652.985507c-42.295652 48.973913-105.368116 97.205797-176.602898 117.982609-44.521739 13.356522-85.333333 18.550725-126.886957 9.646377-42.295652-8.904348-72.718841-28.197101-90.527536-47.489855-8.904348-10.388406-19.292754-22.26087-27.455073-37.843479 0.742029 0.742029 0.742029 2.226087 0.742029 2.968116 0 0-4.452174-7.42029-7.420289-19.292753-1.484058-5.936232-2.968116-11.872464-3.710145-17.808696-0.742029-4.452174-0.742029-8.904348 0-12.614493-0.742029-7.42029 0-15.582609 1.484058-23.744927 4.452174-20.776812 12.614493-43.77971 35.617391-65.298551 48.973913-48.231884 115.014493-50.457971 149.147826-50.457971 50.457971 0.742029 138.017391 22.26087 212.22029 48.973913 20.776812-43.77971 34.133333-89.785507 42.295652-121.692754H304.973913v-33.391304h158.052174v-66.782609H272.324638v-34.133333h190.701449v-66.782609c0-8.904348 2.226087-16.324638 16.324638-16.324637h74.944927v83.107246h207.026087v33.391304H554.295652v66.782609H719.768116S702.701449 494.933333 651.501449 586.202899c115.014493 40.811594 277.518841 104.626087 331.686957 126.144927z m0 0"
            fill="#06B4FD" p-id="2558"></path>
        </svg>{{ $t("onlineRecharge.alipayPayment") }}
      </div>
      <div style="width: 100%; margin: 0 auto; text-align: center">
        <el-tag type="warning">{{ $t("onlineRecharge.des3") }}</el-tag>
        <br />
        <br />
      </div>
      <div style="text-align: center">
        <div class="qr-code" ref="qrCodeUrl"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="wxVisible_jl = false">{{
          $t("onlineRecharge.close")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialog_hf_jl" width="70%">
      <iframe :src="url_jl" frameborder="0" width="100%" height="700"></iframe>
    </el-dialog>
  </el-main>
</template>

<script>
import QRCode from "qrcodejs2";
import BigNumber from "bignumber.js";
import { dataConversionUtil } from "../../plugins/excel.js";
// 增加水印
import { addWatermark, removeWatermark } from "../../utils/waterMark.js";

const lodash = require("lodash");
// removeWatermark();
export default {
  data() {
    return {
      djje: this.$usd_disabled_mon,
      dialog_hf: false,
      btntxt: this.$t("onlineRecharge.submitYourOrder"),
      yjdzje: "",
      value1: new Date(2023, 9, 10, 18, 40),
      name: "在线充值",
      labelPosition: "right",
      isthirdusd: "1",
      form_hw: {
        user: "",
        usd: "",
        service_char: "",
        usdlv: "",
        rechargeNums: "0.00",
        Amount: "0.00",
      },
      form_usdt: {
        user: "",
        usd: "",
        service_char: "",
        usdlv: "",
        rechargeNums: "0.00",
        Amount: "0.00",
      },
      Hai_rd: "2",
      size: "medium",
      orderId: "",
      usdlv: "",
      zfTypes: "1", // 充值类型1汇付   6泰达币   5 香港对公
      yjUsd: "",
      timer: "",
      rmbisok: "1",
      usdisok: "1",
      CurrencyType: "2", // 微信美金充值
      usdJe: "",
      wxVisible: false,
      loading: true,
      resetsinter: null,
      reconnectnum: 0,
      url: "",
      rechargeType: "2",
      form_onlineRecharge: {
        username: "",
        cny: "",
        service_char: "",
        rechargeNums: "0.00",
      },
      form_onlineRechargeRef: {},
      dialog_hf_jl: false,
      name: "充值记录",
      total: 0,
      multipleSelection: [],
      czTypes: "",
      orderId_jl: "",
      url_jl: "",
      wxVisible_jl: false,
      zfurl: "",
      selectVlues: "",
      selectVluesOrder: "",
      dataTimes: [],
      startTime: "",
      endTime: "",
      Rechargedata: [], // 表格数据
      form_recordRules: {}, // 表单验证
      currentPage: 1, // 当前页码
      pageSize: 5, // 每页数据显示 条数
      form_record: {
        orderNo: "",
        amout: "",
      },
      limit_utrecharge: "", // 加密货币充值限制1限制  2不限制
      isChina: true, // 是否是大陆
    };
  },
  methods: {
    // 泰达币充值创建订单
    usdtRechange() {
      this.zfTypes = "6";
      var sub = "";
      var sub = this.usdJe;
      sub = sub.toString();
      const len = sub.indexOf(".");
      if (len === -1) {
        // 如果没有小数点，返回整个整数部分
        this.usdJe = sub
      } else {
        // 如果有小数点，返回整数部分和前两位小数
        this.usdJe = sub.substring(0, len + 3);
      }

      const domainName = window.location.origin;
      if (
        this.form_usdt.rechargeNums < 20 ||
        this.form_usdt.rechargeNums > 100000
      ) {
        this.$message.error("最少充值金额为20,最大充值金额为10W.");
      } else {
        // this.wxVisible = true
        const domainName = window.location.origin;
        let params = {
          money: this.form_usdt.rechargeNums,
          redirect_url: domainName + "#/welcome",
        };
        params = this.$lmjm(params);
        const result = this.$http.post("/api/v1/getUsdtUrl", params, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        });
        result.then(
          (res) => {
            if (res.data.code === 200) {
              const data = res.data.data.data;
              this.url = data.payment_url;
              this.dialog_hf = true;
              // window.open(url)
              this.$message({
                message: "提交订单成功!",
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          },
          (error) => {
            console.log("错误:" + error);
          }
        );
      }
    },
    // 复星充值接口
    subFXRechange(type) {
      // this.zfTypes = "3";
      if (type == "wechat") {
        this.zfTypes = "3";
      } else if (type == "alipay") {
        this.zfTypes = "4";
      }
      var sub = "";
      var sub = this.usdJe;
      sub = sub.toString();
      const len = sub.indexOf(".");
      this.usdJe = sub.substring(0, len + 3);
      if (this.form_hw.rechargeNums < 20 || this.form_hw.rechargeNums > 3000) {
        this.$message.error("最少充值金额为20,最大充值金额为3000.");
      } else {
        this.wxVisible = true;
        //  this.wxVisible_jl = true
        let params = {
          client_id: this.$cookie.get("client_id"),
          money: this.form_hw.rechargeNums,
          recharge_type: type,
          endmoney: this.usdJe,
        };
        params = this.$lmjm(params);
        const result = this.$http.post("/api/v1/getHyPayUrl", params, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        });
        result.then(
          (res) => {
            if (res.data.code === 200) {
              this.orderId = res.data.data.order_num;
              this.url = res.data.data.url;
              new QRCode(this.$refs.qrCodeUrl, {
                text: res.data.data.source_url, // 二维码的内容
                width: 250,
                height: 250,
                colorDark: "#000",
                colorLight: "#fff",
                correctLevel: QRCode.CorrectLevel.H,
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          },
          (error) => {
            console.log("错误:" + error);
          }
        );
      }
    },
    // 离岸充值提交充值申请
    subHaiRechange() {
      this.zfTypes = "1";
      var sub = "";
      var sub = this.usdJe;
      sub = sub.toString();
      const len = sub.indexOf(".");
      if (len === -1) {
        // 如果没有小数点，返回整个整数部分
        this.usdJe = sub
      } else {
        // 如果有小数点，返回整数部分和前两位小数
        this.usdJe = sub.substring(0, len + 3);
      }


      if (this.form_hw.rechargeNums < 20 || this.form_hw.rechargeNums > 3000) {
        this.$message.error("最少充值金额为20,最大充值金额为3000.");
      } else {
        // this.wxVisible = true
        let params = {
          client_id: this.$cookie.get("client_id"),
          money: this.form_hw.rechargeNums,
          endmoney: this.usdJe,
        };
        params = this.$lmjm(params);
        const result = this.$http.post("/api/v1/getHuiPayUrl", params, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        });
        result.then(
          (res) => {
            if (res.data.code === 200) {
              this.orderId = res.data.data.order_num;
              this.url = res.data.data.source_url;
              this.$message({
                message: "提交订单成功!",
                type: "success",
              });
              this.dialog_hf = true;
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          },
          (error) => {
            console.log("错误:" + error);
          }
        );
      }
    },
    // 计算离岸充值的到账金额
    getHaiAmount() {
      const x = new BigNumber(this.form_hw.rechargeNums);
      console.log('x: ', x);
      const y = new BigNumber(this.form_hw.rechargeNums);
      console.log('y: ', y);
      let ls = "";
      ls = y.minus(y.multipliedBy(this.form_hw.service_char / 100));
      ls = ls.toString();
      console.log('ls: ', ls);
      if (ls.indexOf(".") > 0) {
        this.form_hw.Amount = ls.substring(0, ls.indexOf(".") + 3);
      } else {
        this.form_hw.Amount = ls;
      }

      this.usdJe = x.multipliedBy(this.form_hw.usdlv).toString();
      console.log('this.usdJe: ', this.usdJe);
    },
    // 计算加密充值到账金额
    getUsdtAmount() {
      const x = new BigNumber(this.form_usdt.rechargeNums);
      const y = new BigNumber(this.form_usdt.rechargeNums);
      let ls = "";
      ls = y.minus(y.multipliedBy(this.form_usdt.service_char / 100));
      ls = ls.toString();
      if (ls.indexOf(".") > 0) {
        this.form_usdt.Amount = ls.substring(0, ls.indexOf(".") + 3);
      } else {
        this.form_usdt.Amount = ls;
      }

      this.usdJe = x.multipliedBy(this.form_usdt.usdlv).toString();
    },
    getHui() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getHuiUsdcharInfo", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.form_hw.user = res.data.data.username;
            this.form_hw.usd = res.data.data.usd;
            this.form_hw.service_char = res.data.data.service_char;
            this.form_hw.usdlv = res.data.data.usdlv;
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 计算到账金额 (充值金额 - (充值金额 * (充值手续费 + 1%)/100)
    getAmount() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getUsdchargeInfo", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.usdlv = res.data.data.usdlv;
          this.form_onlineRecharge.service_char = res.data.data.service_char;
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
      var cj = (
        this.form_onlineRecharge.rechargeNums *
        ((parseFloat(this.form_onlineRecharge.service_char) + 1) / 100)
      ).toString();
      cj = cj.substring(0, cj.indexOf(".") + 3);
      this.form_onlineRecharge.Amount =
        this.form_onlineRecharge.rechargeNums - parseFloat(cj);
      this.form_onlineRecharge.Amount =
        this.form_onlineRecharge.Amount / this.usdlv;
      if (this.form_onlineRecharge.Amount >= 100) {
        this.form_onlineRecharge.Amount = String(
          this.form_onlineRecharge.Amount
        ).substring(0, 6);
      } else if (this.form_onlineRecharge.Amount < 100) {
        this.form_onlineRecharge.Amount = String(
          this.form_onlineRecharge.Amount
        ).substring(0, 5);
      }
    },
    // 获取余额和充值手续费
    getInfo() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getRechargeInfo", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.form_onlineRecharge.cny = res.data.data.cny;
          this.form_onlineRecharge.service_char = res.data.data.service_char;
          this.form_onlineRecharge.username = res.data.data.username;
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 获取美金汇率接口
    getUsdInfo() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getUsdchargeInfo", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.usdlv = res.data.data.usdlv;
          this.form_onlineRecharge.service_char = res.data.data.service_char;
          this.getUSDPay();
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    getUsds(e) {
      if (e.target.tagName != "INPUT") {
        return;
      }
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getUsdchargeInfo", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.usdlv = res.data.data.usdlv;
          this.form_onlineRecharge.service_char = res.data.data.service_char;
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 提交充值请求
    subOnlineRecharge: lodash.throttle(
      function () {
        this.zfTypes = "0";
        this.url = "";
        if (
          this.form_onlineRecharge.rechargeNums >= 100 &&
          this.form_onlineRecharge.rechargeNums <= 2000
        ) {
          this.wxVisible = true;
          this.getWxPay();
        } else {
          this.$message.error("最少充值金额为100,最大充值金额为2000.");
        }
      },
      3000,
      {
        trailing: false,
      }
    ),
    // 美金充值
    subUsdRecharge: lodash.throttle(
      function () {
        this.url = "";
        if (
          this.form_onlineRecharge.rechargeNums >= 100 &&
          this.form_onlineRecharge.rechargeNums <= 2000
        ) {
          this.wxVisible = true;
          this.getUsdInfo();
        } else {
          this.$message.error("最少充值金额为100,最大充值金额为2000.");
        }
      },
      3000,
      {
        trailing: false,
      }
    ),
    cleanCode() {
      this.$refs.qrCodeUrl.innerHTML = "";
    },
    // 获取接口
    getUserAuth() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getAuth", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.rmbisok = res.data.data.rmbisok;
            this.usdisok = res.data.data.usdisok;
            this.isthirdusd = res.data.data.isthirdusd;
            this.limit_utrecharge = res.data.data.limit_utrecharge;
            this.getAddress()
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 微信USD充值
    getUSDPay() {
      let params = {
        types: this.rechargeType,
        money: this.form_onlineRecharge.rechargeNums,
        endmoney: this.form_onlineRecharge.Amount,
        client_id: this.$cookie.get("client_id"),
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setUsdcharge", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          console.log(res);
          if (res.data.code === 200) {
            this.orderId = res.data.data.order_num;
            this.url = res.data.data.url;
            new QRCode(this.$refs.qrCodeUrl, {
              text: res.data.data.source_url, // 二维码的内容
              width: 250,
              height: 250,
              colorDark: "#000",
              colorLight: "#fff",
              correctLevel: QRCode.CorrectLevel.H,
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 微信人民币充值
    getWxPay() {
      let params = {
        types: this.rechargeType,
        money: this.form_onlineRecharge.rechargeNums,
        endmoney: this.form_onlineRecharge.Amount,
        client_id: this.$cookie.get("client_id"),
      };
      params = this.$lmjm(params);
      console.log(params);
      const result = this.$http.post("/api/v1/setRecharge", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code === 200) {
            this.orderId = res.data.data.order_num;
            this.url = res.data.data.url;
            new QRCode(this.$refs.qrCodeUrl, {
              text: res.data.data.source_url, // 二维码的内容
              width: 250,
              height: 250,
              colorDark: "#000",
              colorLight: "#fff",
              correctLevel: QRCode.CorrectLevel.H,
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    closeTime() {
      this.cleanCode();
      // 同步余额
      this.getHui();
    },
    jyjl() {
      this.$router.push("/rechargeRecord");
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      // 可依据须要在这里定义时间格式
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getRachargOrder();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getRachargOrder();
    },
    // 取消支付
    closeOrder(row) {
      let params = {
        id: row.id,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/cancelpay", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.$message.success("取消订单成功!");
            this.getRachargOrder();
          } else {
            this.$message.error("操作失败，请重试!");
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 获取充值订单
    getRachargOrder(rush) {
      if (rush == "1") {
        this.currentPage = 1;
        this.pageSize = 10;
        this.form_record.orderNo = "";
        this.selectVlues = "";
        this.selectVluesOrder = "";
        this.dataTimes = [];
      }
      if (this.dataTimes[0] == undefined && this.dataTimes[1] == undefined) {
        this.dataTimes[0] = "";
        this.dataTimes[1] = "";
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        order_num: this.form_record.orderNo,
        types: this.selectVlues,
        status: this.selectVluesOrder,
        start: this.dataTimes[0],
        ends: this.dataTimes[1],
      };

      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getreRechargedata", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.total = res.data.data.total;
          this.Rechargedata = res.data.data.data;
          for (let i = 0; i < this.Rechargedata.length; i++) {
            this.Rechargedata[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 重新支付
    subOnlineRecharge(row) {
      this.czTypes = row.types;
      this.zfurl = "";
      this.orderId_jl = "";
      if (row.status == "1") {
        if (row.types != 6) {
          this.wxVisible_jl = true;
        }
        this.orderId_jl = row.order_num;
        this.getWxPay(row.id);
        // this.$nextTick(() => {
        //   document.getElementById("qrcode").innerHTML = "";

        // })
      }
    },
    cleanCode() {
      this.$refs.qrCodeUrl.innerHTML = "";
    },
    // 获取支付连接
    getWxPay(id) {
      this.zfurl = "";
      let params = {
        id: id,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/resetpay", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code === 200) {
            this.zfurl = res.data.data.url;
            if (res.data.data.source_url.indexOf("wxpay") < 0) {
              this.url_jl = res.data.data.source_url;
              this.dialog_hf_jl = true;
            } else {
              new QRCode(this.$refs.qrCodeUrl, {
                text: res.data.data.source_url, // 二维码的内容
                width: 250,
                height: 250,
                colorDark: "#000",
                colorLight: "#fff",
                correctLevel: QRCode.CorrectLevel.H,
              });
            }
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    closeTime() {
      this.cleanCode();
    },
    batchExports() {
      const tableHeader = [
        [
          "订单编号",
          "备注",
          "充值用户",
          "创建时间",
          "充值渠道",
          "金额",
          "预计到账金额",
          "充值币种类型",
          "充值状态",
        ],
      ];
      const dataList = [];
      // 将导出数据存放在this.mumultipleSelection中 ,遍历将要导出的数据
      this.multipleSelection.forEach((item, index) => {
        dataList.push([
          item.order_num,
          item.remark,
          item.users,
          item.createtime,
          item.types,
          item.money,
          item.receipt,
          item.montype,
          item.status,
        ]);
      });
      dataConversionUtil.dataToExcel("导出列表名", tableHeader, dataList);
      this.$message.success("导出成功!");
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    initWebSocket() {
      // 初始化weosocket
      const token = window.sessionStorage.getItem("token");
      const wsuri = "wss://" + this.$domain + "/wss?token=" + token;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      const actions = {
        type: "login",
        client_name: "yy",
        room_id: "1",
      };
      this.websocketsend(JSON.stringify(actions));
      this.resetsinter = setInterval(this.heartbeat, 30000);
    },
    websocketsend(Data) {
      // 数据发送
      this.websock.send(Data);
    },
    websocketonerror(e) {
      // 连接建立失败重连
      this.errorhandle(e);
    },
    websocketclose(e) {
      // 关闭
      this.errorhandle(e);
    },
    errorhandle(e) {
      console.log("连接异常，尝试重连");
      // 连接建立失败，或者连接被关闭，全都到这儿来
      this.reconnectnum++;
      // 允许重连3次，不然这里会无限重连，给服务器带来非常大的压力。
      if (this.reconnectnum < 3) {
        if (this.resetsinter) {
          clearInterval(this.resetsinter);
          this.resetsinter = null;
          this.websock.close();
        }
        this.initWebSocket();
        console.log("断开连接", e);
      } else {
        clearInterval(this.resetsinter);
        this.resetsinter = null;
        // 关闭重连
        console.log(
          "超过3次了，关闭前端websocket所有服务，只能下次刷新后重新尝试连接,因为服务端close连接而导致的前端无限重连"
        );
        // this.websock.close()
      }
    },
    refreshcon() {
      console.log("后端如果向前端发送了websocket");
      // 后端如果向前端发送了websocket，则重新开始计时，过30秒后再发心跳 。
      if (this.resetsinter) {
        clearInterval(this.resetsinter);
        this.resetsinter = null;
      }
      this.resetsinter = setInterval(this.heartbeat, 30000);
      this.reconnectnum = 0;
    },
    heartbeat() {
      const clock = {
        type: "pong",
      };
      this.websocketsend(JSON.stringify(clock));
    },
    websocketonmessage(e) {
      // 数据接收
      console.log(e);
      const data = JSON.parse(e.data);
      switch (data.type) {
        // 服务端ping客户端
        case "firstping":
          const ty = {
            type: "pong",
          };
          this.websocketsend(JSON.stringify(ty));
          this.$cookie.set("client_id", data.client_id);
          break;
        case "ping":
          const clock = {
            type: "pong",
          };
          this.websocketsend(JSON.stringify(clock));
          console.log("心跳回复");
          break;
        case "pingsuccess":
          console.log("ping成功");
          break;
        case "loginsuccess":
          console.log("登录成功");
          break;
        case "wxpay":
          if (data.statu == 2) {
            this.$message({
              message: "支付成功!",
              type: "success",
            });
            this.wxVisible = false;
          } else {
            this.$message({
              message: "支付失败!",
              type: "error",
            });
          }
          break;
        case "real":
          if (data.statu == 2) {
            this.$message({
              message: "实名认证效验通过!",
              type: "success",
            });
          } else {
            this.$message({
              message: "实名认证效验不通过，和注册身份证信息不对应!",
              type: "error",
            });
          }
          break;
        case "downcard":
          console.log("下载最新卡片余额记录进度通知");
          if (data.statu == 2) {
            console.log("同步完成了一个".data.data);
            console.log(
              "全部同步完了，进度完成，可以开始下载了，前端需获悉用户下载的总数，然后不断地递减1,但不管的递减多少，3分钟后进度条就全部跑完。"
            );
          }
          break;
        case "hfpay":
          if (data.statu == 2) {
            this.$message({
              message: "支付成功!",
              type: "success",
            });

            this.dialog_hf = false;
          } else {
            this.$message({
              message: "支付失败!",
              type: "error",
            });
          }
          break;
        case "hypay":
          console.log("data", data);
          if (data.statu == 2) {
            this.$message({
              message: "支付成功!",
              type: "success",
            });
            this.wxVisible = false;
          } else {
            this.$message({
              message: "支付失败!",
              type: "error",
            });
          }
          break;
        case "gopencard":
          this.refreshcon();
          console.log("开卡成功一张!!");
          break;
        case "opencard":
          this.refreshcon();
          console.log("开卡成功一张!!");
          break;
      }
    },
    // 获取usdt充值手续费
    getUsdtInfo() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getUsdtcharInfo", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.form_usdt.user = res.data.data.username;
          this.form_usdt.usd = res.data.data.usd;
          this.form_usdt.service_char = res.data.data.service_char;
          this.form_usdt.usdlv = res.data.data.usdlv;
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 获取ip
    getAddress() {
      let params = {};
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/getipaddress', params, {
        headers: {
        }
      })
      result.then((res) => {
        console.log('res: ', res);
        if (res.data.data.in_china == 1) {
          // 大陆
          this.isChina = true
        } else {
          // 海外
          this.isChina = false
          if (this.limit_utrecharge == '2') {
            this.zfTypes = '6'
          } else {
            this.zfTypes = '5'
          }
        }
      }, (error) => {
        console.log('错误:' + error)
      })
    }
  },
  watch: {},
  created() {
    this.getInfo();
    this.initWebSocket();
    this.getUserAuth();
    this.getHui();
    this.getUsdtInfo();
  },
  mounted() {
    // addWatermark("谨防诈骗");
  },
  beforeDestroy() { },
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item{
  margin-bottom: 45px;
}
.el-radio.is-bordered.is-checked {
  border-color: #725cda !important;
}

.tb_img {
  position: absolute;
  width: 42px;
  left: 0;
  top: -10px;
}

.xts {
  position: absolute;
  background-color: #725cda;
  color: #ffffff;
  top: -30px;
  padding: 0 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 30px;
  line-height: 30px;
}

.dzjes {
  font-size: 28px;
  font-family: Rubik;
  font-weight: 600;
  color: #48b338;
  line-height: 32px;
}

.el-radio__input.is-checked+.el-radio__label {
  color: #000000;
}

.el-radio.is-bordered {
  padding: 0;
  text-align: center;
  width: 140px;
  line-height: 45px;
  height: 45px;
  /* margin-top: 20px; */
}

.el-radio img {
  position: relative;
  top: 4px;
  margin-right: 6px;
  padding-left: 10px;
}

.zcje {
  border-radius: 15px;
  height: 194px;
  padding: 10px 20px;
  margin-bottom: 24px;
  background: var(--mainCard);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.12);
}

.wx_content {
  padding: 3% 4%;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 0px rgb(0 0 0 / 0%) !important;
}

.cc {
  position: relative;
  top: 4px;
  left: 4px;
}

svg {
  width: 20px;
  height: 20px;
}

.jxk {
  position: relative;
  top: 5px;
}

.usds {
  left: 1px;
}

.el-checkbox__inner {
  border: 1px solid #000000;
}

.el-select,
.el-input--prefix {
  margin-right: 1%;
}

.el-input--prefix {
  width: 15%;
}

.el-range-editor {
  margin-right: 3%;
}

.el-button {
  margin-right: 2%;
}

.qrcode {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  padding: 2%;
}

.wx_content {
  padding: 3% 4%;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 0px rgb(0 0 0 / 0%) !important;
}

svg {
  width: 30px;
  height: 30px;
}

</style>
<style>
.el-tabs__header,
.el-tabs__nav-wrap {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.tooltip-width{
  width: 250px !important;
  max-width: 250px !important;
}
/* .el-tooltip__popper.is-dark{
  width: 250px !important;
  max-width: 250px !important;
} */
</style>
