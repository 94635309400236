<template>
  <el-main>
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{ $t('affiliatePromotion.home') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('affiliatePromotion.title') }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <!-- 输入卡号 -->
            <el-input style="
                width: 15%;
                margin-right: 20px;
                margin-bottom: 20px;
                float: left;
              " v-model="queryUser" :placeholder="$t('affiliatePromotion.des1')" @change="getCustomdata">
            </el-input>
            <!-- 客户管理表格 -->
            <el-table :data="tableData" style="width: 100%" v-loading="vLoading" height="650" max-height="650"
              size="small" highlight-current-row>
              <el-table-column label="id" prop="new_join_id" v-if="false">
              </el-table-column>
              <el-table-column :label="$t('affiliatePromotion.username')" prop="users" width="300"> </el-table-column>
              <el-table-column :label="$t('affiliatePromotion.timeOfRegistration')" prop="createtime" width="200">
              </el-table-column>
              <el-table-column :label="$t('affiliatePromotion.invitationCode')" prop="invitation" width="200">
              </el-table-column>
              <!-- <el-table-column label="总充值金额" prop="rechargeSums">
              </el-table-column> -->
              <!-- 用户状态标签 -->
              <el-table-column prop="status" :label="$t('affiliatePromotion.userStatus')" width="200">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status === 1" type="success">
                    {{ $t('affiliatePromotion.normal') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.status === 2" type="info">
                    {{ $t('affiliatePromotion.deactivated') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.status === 3" type="warning">
                    {{ $t('affiliatePromotion.toBeReviewed') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.status === 4" type="danger">
                    {{ $t('affiliatePromotion.notPassed') }}
                  </el-tag>
                  <!-- <el-tag :type="scope.row.status === 1 ? 'primary' : 'success'">{{scope.row.status}}
                  </el-tag> -->
                </template>
              </el-table-column>
              <!-- 拒付率 -->
              <el-table-column label="拒付率" sortable prop="relv" width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.relv < 50">
                    {{ scope.row.relv }}%
                  </el-tag>
                  <el-tag type="danger" v-else-if="scope.row.relv >= 50">
                    {{ scope.row.relv }}%
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column :label="$t('affiliatePromotion.operation')">
                <template slot-scope=" scope">
                  <el-button v-if="agent_edit == 1" v-no-more-click size="mini" type="danger"
                    @click="getUserRecord(scope.row)">{{
                      $t('affiliatePromotion.rejectRecorder') }}</el-button>
                  <el-button size="mini" type="primary" @click="searchRecod(scope.row)">{{
                    $t('affiliatePromotion.rechargeHistory') }}</el-button>
                  <el-button size="mini" type="primary" @click="searchCard(scope.row)">{{
                    $t('affiliatePromotion.inquireAboutTheCard') }}</el-button>
                  <el-button v-if="agent_edit == 1" size="mini" type="primary" @click="openCardForm(scope.row)">{{
                    $t('affiliatePromotion.cardConfig') }}</el-button>
                  <el-button v-if="agent_edit == 1" v-no-more-click size="mini" type="primary"
                    @click="openPriceForm(scope.row)">{{
                      $t('affiliatePromotion.priceConfig') }}</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[20, 30, 40, 100]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="userTotal">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 卡段配置 -->
    <el-dialog :title="$t('affiliatePromotion.cardConfig')" custom-class="ls" :visible.sync="carConfigModal" width="30%"
      @close="closeCarModal">
      <!-- <el-input
        v-model="carCofigInput"
        placeholder="请输入卡段(用逗号隔开)"
        style="margin-bottom: 20px"
      ></el-input> -->
      <div class="carcontainer">
        <!-- <div class="select_Card">
          <el-tag class="select_Card_item" @click="selectCH('JP')">{{ $t('affiliatePromotion.plantag') }}</el-tag>
          <el-tag class="select_Card_item" @click="selectCH('GG')">{{ $t('affiliatePromotion.addtag') }}</el-tag>
          <el-tag class="select_Card_item" @click="selectCH('DS')">{{ $t('affiliatePromotion.shoptag') }}</el-tag>
          <el-tag class="select_Card_item" @click="selectCH('DY')">{{ $t('affiliatePromotion.apptag') }}</el-tag>
          <el-tag class="select_Card_item" @click="selectCH('QT')">{{ $t('affiliatePromotion.othertag') }}</el-tag>
        </div> -->
        <el-select v-model="carCofigInput" multiple>
          <el-option v-for="(d, index) in cardoptions" :key="index" :label="d" :value="d">
          </el-option>
        </el-select>
        <el-button style="width: 120px; margin-top: 20px" @click="setUserCarConfig">{{
          $t('affiliatePromotion.comfirmBtn') }}</el-button>
      </div>
    </el-dialog>
    <!-- 价格设置按钮 -->
    <el-dialog custom-class="ls" :title="$t('affiliatePromotion.priceBtn')" :visible="price_FormVisible" width="30%"
      @close="closePrice">
      <!-- 流水记录表单 -->
      <el-form :model="price_Form" :label-position="labelPosition">
        <el-divider content-position="left">{{ $t('affiliatePromotion.production') }}</el-divider>
        <el-form-item :label="$t('affiliatePromotion.isUserSetConfig')">
          <el-switch v-model="price_Form.pis_card_openlv" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <el-form-item :label="$t('affiliatePromotion.cardFee')" :label-width="formLabelWidth">
          <el-input v-model="price_Form.popencardlv"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('affiliatePromotion.isUserSetConfig')">
          <el-switch v-model="price_Form.pis_card_uselv" :active-value="1" :inactive-value="2">
          </el-switch>
        </el-form-item>
        <el-form-item :label="$t('affiliatePromotion.rechargeFee')" :label-width="formLabelWidth">
          <el-input v-model="price_Form.pservice_card"></el-input>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="setUserLvConfig">{{ $t('affiliatePromotion.changeBtn') }}</el-button>
      </div>
    </el-dialog>
    <!-- 流水记录弹窗 -->
    <el-dialog custom-class="ls" :title="$t('affiliatePromotion.turnoverRecords')"
      :visible.sync="consumptionFormVisible" width="90%">
      <!-- 流水记录表单 -->
      <el-form :model="form_record" :label-position="labelPosition">
        <!-- 订单号 -->
        <el-input v-model="form_record.orderNo" prefix-icon="el-icon-tickets"
          :placeholder="$t('affiliatePromotion.des2')" style="width: 10%">
        </el-input>
        <!-- 充值渠道 -->
        <el-select v-model="rechageQd" :placeholder="$t('affiliatePromotion.rechargeChannels')" clearable>
          <el-option v-for="item in qdOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker v-model="dataTimes" type="daterange" align="right" unlink-panels range-separator="-"
          :start-placeholder="$t('affiliatePromotion.startDate')" :end-placeholder="$t('affiliatePromotion.endDate')"
          :picker-options="pickerOptions2" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
        </el-date-picker>
        <el-button style="margin-left: 40px" icon="el-icon-search" @click="scRecodeJl()">{{
          $t('affiliatePromotion.search') }}</el-button>
        <el-button icon="el-icon-refresh" @click="scRecodeJl(1)">{{ $t('affiliatePromotion.brushNew')
          }}</el-button>
      </el-form>
      <!-- 流水记录表格 -->
      <el-table :data="userRecod" style="width: 100%" height="550" max-height="550" size="small" highlight-current-row>
        <!-- id- ids，用户名-name，手机号码-tel，上级名称-superior，用户状态-status，注册时间-date，余额-balance，拒付率-dishonor，操作 -->
        <el-table-column label="id" prop="id" v-if="false"> </el-table-column>
        <el-table-column :label="$t('affiliatePromotion.orderNumber')" prop="order_num"> </el-table-column>
        <el-table-column :label="$t('affiliatePromotion.theUserToWhomItBelongs')" prop="users"> </el-table-column>
        <el-table-column :label="$t('affiliatePromotion.operators')" prop="actionusers"> </el-table-column>
        <el-table-column :label="$t('affiliatePromotion.operatingTime')" prop="createtime" sortable>
        </el-table-column>
        <el-table-column :label="$t('affiliatePromotion.rechargeChannels')" prop="types">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.types === 1"> {{ $('affiliatePromotion.alipay') }} </el-tag>
            <el-tag v-else-if="scope.row.types === 2"> {{ $t('affiliatePromotion.wechat') }} </el-tag>
            <el-tag v-else-if="scope.row.types === 3"> {{ $t('affiliatePromotion.bankCards') }} </el-tag>
            <el-tag v-else-if="scope.row.types === 4" type="warning">
              {{ $t('affiliatePromotion.manualTopUp') }}
            </el-tag>
            <el-tag v-else-if="scope.row.types === 5" type="danger">
              {{ $t('affiliatePromotion.manualDeductions') }}
            </el-tag>
            <el-tag v-else-if="scope.row.types === 6"> {{ $t('affiliatePromotion.offshoreTopUp') }} </el-tag>
            <el-tag v-else-if="scope.row.types === 7" type="danger"> {{ $t('affiliatePromotion.cryptocurrencies') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column :label="$t('affiliatePromotion.theTypeOfDepositAmount')" prop="montype" sortable>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.montype === 1"> CNY </el-tag>
            <el-tag v-else-if="scope.row.montype === 2"> USD </el-tag>
          </template>
        </el-table-column>
        <el-table-column :label="$t('affiliatePromotion.theAmountReceived')" prop="receipt" sortable>
        </el-table-column>
        <el-table-column :label="$t('affiliatePromotion.remark')" prop="remark" sortable> </el-table-column>
        <!-- 充值状态 -->
        <el-table-column prop="status" :label="$t('affiliatePromotion.topUpStatus')" width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1" type="warning">
              {{ $t('affiliatePromotion.waitForPayment') }}
            </el-tag>
            <el-tag v-else-if="scope.row.status === 2" type="success">
              {{ $t('affiliatePromotion.success') }}
            </el-tag>
            <el-tag v-else-if="scope.row.status === 3" type="danger">
              {{ $t('affiliatePromotion.fail') }}
            </el-tag>
            <el-tag v-else-if="scope.row.status === 4" type="info">
              {{ $t('affiliatePromotion.cancelThePayment') }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination align="center" @size-change="userRecodhandleSizeChange"
        @current-change="userRecodhandleCurrentChange" :current-page="userRecodcurrentPage"
        :page-sizes="[100, 500, 1000]" :page-size="userRecodpageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="userRecodTotal">
      </el-pagination>
    </el-dialog>
    <!-- 查询卡片弹窗 -->
    <el-dialog :title="$t('affiliatePromotion.queryCards')" custom-class="ls" :visible.sync="searchCardsFormVisible"
      width="90%">
      <!-- 查询卡片表单 -->
      <el-form :model="form_queryCards" ref="form_queryCardsRef">
        <!-- 卡号 -->
        <el-input v-model="form_queryCards.cardNo" prefix-icon="el-icon-bank-card" style="width: 180px"
          :placeholder="$t('affiliatePromotion.des3')">
        </el-input>
        <!-- 开卡日期查询 -->
        <el-date-picker v-model="selectTimes" type="date" :placeholder="$t('affiliatePromotion.dateOfApplication')"
          format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
        </el-date-picker>
        <!-- 卡片状态 -->
        <el-select v-model="cardsTypesVal" :placeholder="$t('affiliatePromotion.cardStatus')">
          <el-option v-for="item in cardsTypes" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button icon="el-icon-search" @click="searchCard()">{{ $t('affiliatePromotion.search')
          }}</el-button>
        <el-button icon="el-icon-refresh" @click="searchCard('rf')">{{ $t('affiliatePromotion.brushNew')
          }}</el-button>
      </el-form>
      <!-- 查询卡片表格 -->
      <el-table :data="cardsData" style="width: 100%" height="550" max-height="550" size="small"
        @expand-change="(row) => UserCarShoplist(row)" highlight-current-row>
        <!-- 展开卡片详情表格 -->
        <el-table-column type="expand" :label="$t('affiliatePromotion.cardTransactionHistory')" width="100">
          <template slot-scope="props">
            <template>
              <el-table :header-cell-style="{ background: '#909399', color: '#ffffff' }" :data="cardsRechageRecodeData"
                style="width: 100%" size="small" highlight-current-row height='250'>
                <el-table-column prop="card_num" :label="$t('affiliatePromotion.cardNumber')" width="180">
                </el-table-column>
                <el-table-column prop="shopname" :label="$t('affiliatePromotion.theNameOfTheBusiness')" width="120"
                  :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="createtime" :label="$t('affiliatePromotion.orderTime')"
                  :show-overflow-tooltip="true" width="150">
                </el-table-column>
                <el-table-column prop="money" :label="$t('affiliatePromotion.theAmountSpent')" width="100">
                </el-table-column>
                <!-- 交易类型 -->
                <el-table-column :label="$t('affiliatePromotion.theTypeOfTransaction')" width="160">
                  <template slot-scope="scope">
                    <!-- <el-tag
                      v-if="scope.row.event_type === '6810'"
                      effect="dark"
                      type="success"
                    >
                      消费授权(通过)
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.event_type === '6890'"
                      effect="dark"
                      type="danger"
                    >
                      消费授权(拒绝)
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.event_type === '6510'"
                      effect="dark"
                      type="success"
                    >
                      退款授权(通过)
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.event_type === '6590'"
                      effect="dark"
                      type="danger"
                    >
                      退款授权(拒绝)
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.event_type === '6930'"
                      effect="dark"
                      type="success"
                    >
                      消费授权冲正
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.event_type === '6940'"
                      effect="dark"
                      type="danger"
                    >
                      退款授权冲正
                    </el-tag> -->
                    <el-tag v-if="scope.row.event_type === 'auth'" effect="dark" type="success">
                      {{ $t('saleType.consume') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'corrective_auth'" effect="dark" type="success">
                      {{ $t('saleType.correctionOfAuthorization') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'verification'" effect="dark" type="success">
                      {{ $t('saleType.verify') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'void'" effect="dark" type="warning">
                      {{ $t('saleType.quash') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'refund'" effect="dark" type="warning">
                      {{ $t('saleType.refund') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'corrective_refund'" effect="dark" type="danger">
                      {{ $t('saleType.validRefunds') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'recharge'" effect="dark" type="success">
                      {{ $t('saleType.recharge') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'recharge_return'" effect="dark" type="success">
                      {{ $t('saleType.cardBalanceRefund') }}
                    </el-tag>
                    <el-tag v-else-if="
                      scope.row.event_type === 'discard_recharge_return'
                    " effect="dark" type="warning">
                      {{ $t('saleType.theCancelledCardIsReturned') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'service_fee'" effect="dark" type="success">
                      {{ $t('saleType.serviceCharge') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'refund_reversal'" effect="dark" type="danger">
                      {{ $t('saleType.revocationOfRefunds') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'Auth'" effect="dark" type="success">
                      {{ $t('saleType.authorizedConsumption') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'Reversal'" effect="dark" type="warning">
                      {{ $t('saleType.refund') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'card_out'" effect="dark" type="warning">
                      {{ $t('saleType.theCardIsTransferredOut') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.event_type === 'card_in'" effect="dark" type="warning">
                      {{ $t('saleType.cardTransferIn') }}
                    </el-tag>
                  </template>
                </el-table-column>
                <!-- 交易状态 -->
                <el-table-column prop="statu" :label="$t('affiliatePromotion.transactionStatus')" width="100">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.statu === 1" type="success">
                      {{ $t('saleStatus.theTransactionWasSuccessful') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.statu === 2" type="danger">
                      {{ $t('saleStatus.theTransactionFailed') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.statu === 3" type="warning">
                      {{ $t('saleStatus.trading') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.statu === 4" type="success">
                      {{ $t('saleStatus.theTransactionReversalIsSuccessful') }}
                    </el-tag>
                    <el-tag v-else-if="scope.row.statu === 5" type="danger">
                      {{ $t('saleStatus.transactionReversalFailed') }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="desct" :label="$t('affiliatePromotion.transactionDescription')"
                  :show-overflow-tooltip="true" width="120">
                </el-table-column>
                <el-table-column prop="moneytype" :label="$t('affiliatePromotion.theCurrencyOfTheTransaction')"
                  width="120">
                </el-table-column>
              </el-table>
              <!-- 卡交易记录表格分页 -->
              <el-pagination class="pagesRecod" align="center" @size-change="CardRecodhandleSizeChange"
                @current-change="CardRecodhandleCurrentChange" :current-page="CardRecodcurrentPage"
                :page-sizes="[5, 10, 15, 20]" :page-size="CardRecodpageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="cardsRechageRecodeDataTotal">
              </el-pagination>
            </template>
          </template>
        </el-table-column>
        <!-- 卡类型 -->
        <el-table-column prop="ctype" :label="$t('affiliatePromotion.cardType')" width="90">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.ctype == '共享卡'" type="danger">
              {{ scope.row.ctype }}
            </el-tag>
            <el-tag v-else type="success">
              {{ scope.row.ctype }}
            </el-tag>
          </template>
        </el-table-column>
        <!-- 卡号 -->
        <el-table-column prop="num" :label="$t('affiliatePromotion.cardNumber')" width="150"> </el-table-column>
        <!-- 币种 -->
        <el-table-column prop="types" :label="$t('affiliatePromotion.currency')" width="90">
        </el-table-column>
        <!-- 开卡日期 -->
        <el-table-column prop="createtime" :label="$t('affiliatePromotion.signUpDate')" width="150">
        </el-table-column>
        <!-- 卡状态 -->
        <el-table-column :label="$t('affiliatePromotion.cardStatus')" width="90">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1" type="success"> {{ $t('affiliatePromotion.normal') }} </el-tag>
            <el-tag v-else-if="scope.row.status === 2" type="danger">
              {{ $t('affiliatePromotion.locked') }}
            </el-tag>
            <el-tag v-else-if="scope.row.status === 3" type="info">
              {{ $t('affiliatePromotion.cancellationOfTheCard') }}
            </el-tag>
          </template>
        </el-table-column>

        <!-- 卡余额 -->
        <el-table-column :label="$t('affiliatePromotion.availableBalance')" width="150" sortable>
          <template slot-scope="scope">
            {{ scope.row.usdmoney }}
          </template>
        </el-table-column>
        <!-- 卡备注 -->
        <el-table-column prop="remark" :label="$t('affiliatePromotion.cardNotes')" :show-overflow-tooltip="true">
        </el-table-column>
        <!-- 查询卡片操作 -->
        <el-table-column :label="$t('affiliatePromotion.operation')" v-if="agent_edit == 1">
          <template slot-scope="scope">
            <!-- <el-button size="mini" v-if="scope.row.status == 1 && scope.row.status != 3" type="info"
              @click="LockCard(scope.row)">锁卡</el-button> -->
            <!-- <el-button size="mini" v-if="scope.row.status == 2 && scope.row.status != 3" v-no-more-click type="success"
              @click.native.stop="jk(scope.row)">解卡</el-button> -->
            <!-- <el-button v-if="scope.row.status != 3" v-no-more-click type="primary" icon="el-icon-refresh-left"
              @click.native.stop="SyncMoney(scope.row)" size="mini">同步</el-button> -->
            <el-button size="mini" v-no-more-click type="primary" v-if="
              scope.row.istoslide != 2 &&
              scope.row.status == 1 &&
              scope.row.status != 3
            " @click.native.stop="transferCard(scope.row)">{{ $t('affiliatePromotion.cardTopUp') }}
            </el-button>
            <!-- <el-button size="mini" v-if="scope.row.status != 3" type="danger" @click="delUserCard(scope.row)">销卡
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 查询卡片分页 -->
      <el-pagination align="center" @size-change="searchCardhandleSizeChange"
        @current-change="searchCardhandleCurrentChange" :current-page="searchCardcurrentPage"
        :page-sizes="[10, 20, 30, 40]" :page-size="searchCardpageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="cxcardTotal">
      </el-pagination>
    </el-dialog>
    <!-- 拒付面板 -->
    <el-dialog :title='$t("affiliatePromotion.managerPannel")' custom-class="ls" :visible.sync="DialogMngPanel"
      width="90%">
      <el-table :data="userRejectRecod" style="width: 100%" height="550" max-height="550" size="small"
        highlight-current-row>
        <el-table-column prop="nums" :label='$t("affiliatePromotion.cardN")'>
          <template slot-scope="scope">
            <el-tag effect="dark">{{ scope.row.nums }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="lv" :label='$t("affiliatePromotion.Refusal")'>
          <template slot-scope="scope">
            <el-tag type="danger" effect="dark">{{ scope.row.lv }}%</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="allnum" :label='$t("affiliatePromotion.totalSale")'> </el-table-column>
        <el-table-column prop="renums" :label='$t("affiliatePromotion.totalFail")'> </el-table-column>
        <el-table-column prop="renums" :label='$t("affiliatePromotion.totalRefuse")'> </el-table-column>
        <el-table-column prop="allmoney" :label='$t("affiliatePromotion.totalMoney")'> </el-table-column>
        <el-table-column prop="renmoney" :label='$t("affiliatePromotion.failMoney")'> </el-table-column>
        <el-table-column prop="errmoney" :label='$t("affiliatePromotion.refuseMoney")'> </el-table-column>
        <el-table-column prop="updatetime" :label='$t("affiliatePromotion.upTime")'> </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 卡号转出弹出框 -->
    <el-dialog custom-class="ls" v-dialogDrag :title='$t("affiliatePromotion.cardTransfer")' :visible.sync="dialogZc"
      width="50%" ref="dialog__wrapper">
      <el-form :label-position="labelPosition" label-width="80px" :model="form_zc">
        <el-form-item :label='$t("affiliatePromotion.cardNum")'>
          <el-input v-model="form_zc.cardNo" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item :label='$t("affiliatePromotion.cardbalance")'>
          <el-input v-model="form_zc.balance" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item :label='$t("affiliatePromotion.transferAmount")'>
          <el-input v-model="form_zc.zc_balance"></el-input>
        </el-form-item>
        <el-form-item :label='$t("affiliatePromotion.remark")'>
          <el-input v-model="form_zc.zc_remarks"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <span slot="footer">
          <el-button @click="dialogZc = false">{{ $t("affiliatePromotion.cardCanel") }}</el-button>
          <el-button @click="cardZc()">{{ $t("affiliatePromotion.comfirmBtn") }}</el-button>
        </span>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      name: "客户管理",
      DialogMngPanel: false, // 用户拒付记录
      form_zc: {
        cardNo: "",
        balance: "",
        zc_balance: "",
        zc_remarks: "",
      },
      dialogZc: false,
      cardshop: "",
      form_record: {},
      recodeId: "",
      dataTimes: [],
      userTotal: 0,
      userRecodTotal: 0,
      tableData: [],
      vLoading: false,
      pickerOptions2: "",
      labelPosition: "right",
      consumptionFormVisible: false,
      userRecodcurrentPage: 1, // 用户交易记录当前页码
      userRecodpageSize: 10, // 用户交易记录每页数据显示 条数
      // 充值渠道
      qdOptions: [
        {
          value: "1",
          label: this.$t('affiliatePromotion.alipay'),
        },
        {
          value: "2",
          label: this.$t('affiliatePromotion.wechat'),
        },
        {
          value: "3",
          label: this.$t('affiliatePromotion.bankCards'),
        },
        {
          value: "4",
          label: this.$t('affiliatePromotion.manualTopUp'),
        },
        {
          value: "5",
          label: this.$t('affiliatePromotion.manualDeductions'),
        },
        {
          value: "6",
          label: this.$t('affiliatePromotion.offshoreTopUp'),
        },
        {
          value: "7",
          label: this.$t('affiliatePromotion.cryptocurrencies'),
        },
      ],
      rechageQd: "",
      // 用户交易记录
      userRecod: [],
      queryUser: "", // 查询用户名
      currentPage: 1, // 当前页码
      pageSize: 100, // 每页数据显示 条数
      scCdIds: "",
      searchCardcurrentPage: 1,
      searchCardpageSize: 10,
      form_queryCards: {},
      cardsTypesVal: "",
      selectTimes: "",
      cardsData: [],
      cxcardTotal: 0,
      searchCardsFormVisible: false,
      userRejectRecod: [], // 用户拒付记录
      cardsTypes: [
        {
          value: "1",
          label: this.$t('affiliatePromotion.normal'),
        },
        {
          value: "2",
          label: this.$t('affiliatePromotion.locked'),
        },
        {
          value: "3",
          label: this.$t('affiliatePromotion.cancellationOfTheCard'),
        },
      ],
      cardsRechageRecodeData: [],
      CardRecodcurrentPage: 1,
      CardRecodpageSize: 5,
      cardIds: "",
      price_Form: {
        pis_card_openlv: 2,
        pis_card_uselv: 2,
        popencardlv: "0.00",
        pservice_card: "0.00"
      }, // 价格设置
      price_FormVisible: false, // 设置表单可见性
      formLabelWidth: "120px",
      carConfigModal: false, // 卡段配置按钮
      carCofigInput: "", // 卡段选择器
      cardoptions: [],
      agent_edit: 2, // 代理商权限
    };
  },
  methods: {
    cardZc() {
      if (this.cardshop == "2") {
        if (this.form_zc.balance - this.form_zc.zc_balance < 1) {
          this.$message({
            message:
              "你需要在卡上至少保留1美元。如果您不再使用该卡，您可以销卡，我们将把卡上的所有资金退还给您。",
            type: "error",
          });
          return;
        }
      }
      let params = {
        id: this.form_zc.id,
        money: this.form_zc.zc_balance,
        remark: this.form_zc.zc_remarks,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setManCardRoOu", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.$message({
              message: "转出成功!",
              type: "success",
            });
            this.searchCard();
            this.dialogZc = false;
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 卡转出
    transferCard(item) {
      this.cardshop = item.cardshop;
      this.dialogZc = true;
      this.form_zc.cardNo = item.num;
      let params = {
        id: item.id,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getManNewCardUsd", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          console.log('res: ', res);
          this.form_zc.balance = res.data.data.usdmoney;
          this.form_zc.id = item.id;
          console.log('this.form_zc: ', this.form_zc);
          this.dialogZc = true;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 拒付记录
    getUserRecord(item) {
      let params = {
        id: item.new_join_id,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getUserRecord", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            console.log(res);
            this.userRejectRecod = res.data.data.data;
            for (let i = 0; i < this.userRejectRecod.length; i++) {
              this.userRejectRecod[i].updatetime = this.getTime(
                res.data.data.data[i].updatetime
              );
            }
            this.DialogMngPanel = true
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 关闭价格弹窗
    closePrice() {
      this.price_Form = {}
      this.scCdIds = ""
      this.price_FormVisible = false
    },
    // 修改开卡费用
    setUserLvConfig() {
      this.price_Form.id = this.scCdIds
      console.log('this.price_Form: ', this.price_Form);
      if (parseFloat(this.price_Form.popencardlv) < 0.8) {
        this.$message.warning(this.$t("affiliatePromotion.tips"))
        return
      }
      const params = this.$lmjm(this.price_Form);
      const result = this.$http.post("/api/v1/editUserdata", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(res => {
        if (res.data.code == 200) {
          this.$message.success("修改成功")
        } else {
          this.$message.error('修改失败')
        }
        this.closePrice()
      })
    },
    // 根据用户id获取
    async getUserById() {
      let options = {
        id: this.scCdIds,
      };
      options = this.$lmjm(options);
      await this.$http.post("/api/v1/getEditUser", options, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      }).then(res => {
        if (res.data.code == 200) {
          this.price_Form = res.data.data
          this.price_FormVisible = true
        } else {
          this.price_Form = {}
        }
      });
    },
    // 获取用户信息
    getUser() {
      const result = this.$http.post(
        "/api/v1/getuserinfos",
        {},
        {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        }
      );
      result.then((res) => {
        this.agent_edit = res.data.data.agent_edit;
      });
    },
    closeCarModal() {
      this.carCofigInput = "";
      this.scCdIds = "";
      this.carConfigModal = false;
    },
    // 查询所有卡段
    getCardList() {
      let params = {
        page: 1,
        page_size: 999,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getApplyProduct", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then((res) => {
        const cards = res.data.data.data;
        const s = new Set();
        cards.forEach((item) => {
          s.add(item.cardarea);
        });
        this.cardoptions = [...s];
      });
    },
    // 打开配置
    openCardForm(row) {
      this.carCofigInput = row.allow_cards.split(",").filter(card => card.trim() !== "");;
      this.scCdIds = row.new_join_id;
      this.carConfigModal = true;
    },
    // 配置卡端
    selectCH(type) {
      if (type == "JP") {
        this.carCofigInput.push(
          ...["532959", "515783", "491724", "486695", "223467", "428842", "493875"]
        );
      } else if (type == "GG") {
        this.carCofigInput.push(...["532959", "515783", "491724", "486695", "428842", "493875"]);
      } else if (type == "DS") {
        this.carCofigInput.push(...["532959"]);
      } else if (type == "DY") {
        this.carCofigInput.push(...["486695", "428842"]);
      } else if (type == "QT") {
        this.carCofigInput.push(...["466695", "491724"]);
      }
      let uniqueArray = [...new Set(this.carCofigInput)];
      this.carCofigInput = uniqueArray;
    },
    // 确定卡段配置
    setUserCarConfig() {
      let params = {
        id: this.scCdIds,
        type: "0",
        allow_cards: this.carCofigInput.join(","),
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/editAllowCard", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then((res) => {
        if (res.data.code == "200") {
          this.$message({
            message: "修改成功!",
            type: "success",
          }),
            (this.glyDloag = false);
        } else {
          this.$message({
            message: "修改失败!",
            type: "error",
          });
        }
        this.closeCarModal();
        this.getCustomdata();
      });
    },
    // 打开价格设置弹窗
    openPriceForm(row) {
      // this.price_Form = {}
      this.scCdIds = row.new_join_id;
      this.getUserById()

    },
    // 卡交易记录分页
    CardRecodhandleCurrentChange(val) {
      this.CardRecodcurrentPage = val;
      this.UserCarShoplist();
    },
    // 卡交易记录分页
    CardRecodhandleSizeChange(val) {
      this.CardRecodcurrentPage = 1;
      this.CardRecodpageSize = val;
      this.UserCarShoplist();
    },
    // 卡交易记录查询
    UserCarShoplist(row) {
      // this.cardsRechageRecodeData = [];
      // this.cardsRechageRecodeDataTotal = 0;
      if (row != undefined) {
        this.cardIds = row.id;
      }
      let params = {
        page: this.CardRecodcurrentPage,
        limit: this.CardRecodpageSize,
        card_id: this.cardIds,
        id: this.scCdIds,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getUserCarShoplist", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.cardsRechageRecodeData = res.data.data.data;
          this.cardsRechageRecodeDataTotal = res.data.data.total;
          console.log('this.CardRecodcurrentPage: ', this.CardRecodcurrentPage);

          for (let i = 0; i < this.cardsRechageRecodeData.length; i++) {
            this.cardsRechageRecodeData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 查询卡分页
    searchCardhandleCurrentChange(val) {
      this.searchCardcurrentPage = val;
      this.searchCards();
    },
    // 查询卡分页
    searchCardhandleSizeChange(val) {
      this.searchCardcurrentPage = 1;
      this.searchCardpageSize = val;
      this.searchCards();
    },
    // 查询用户开卡
    searchCard(index) {
      this.cardsData = [];
      this.cxcardTotal = 0;
      if (index == "rf") {
        this.form_queryCards.cardNo = "";
        this.cardsTypesVal = "";
        this.selectTimes = "";
      }
      if (index != undefined && index != "rf") {
        this.scCdIds = index.new_join_id;
      }
      this.searchCardsFormVisible = true;
      let params = {
        id: this.scCdIds,
        page: this.searchCardcurrentPage,
        limit: this.searchCardpageSize,
        card_num: this.form_queryCards.cardNo,
        statu: this.cardsTypesVal,
        createtime: this.selectTimes,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getUserCardData", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.cardsData = res.data.data.data;
          this.cxcardTotal = res.data.data.total;
          for (let i = 0; i < this.cardsData.length; i++) {
            this.cardsData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
            if (
              this.cardsData[i].card_type == "share" ||
              this.cardsData[i].card_type == "SHARE"
            ) {
              this.cardsData[i].ctype = "共享卡";
            } else {
              this.cardsData[i].ctype = "充值卡";
            }
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      // 可依据须要在这里定义时间格式
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    // 用户消费记录分页
    userRecodhandleCurrentChange(val) {
      this.userRecodcurrentPage = val;
      this.scRecodeJl();
    },
    // 用户消费记录分页
    userRecodhandleSizeChange(val) {
      this.userRecodcurrentPage = 1;
      this.userRecodpageSize = val;
      this.scRecodeJl();
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getCustomdata();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getCustomdata();
    },
    // 查询流水记录
    searchRecod(index) {
      this.recodeId = index.new_join_id;
      this.consumptionFormVisible = true;
      if (this.dataTimes[0] == undefined && this.dataTimes[1] == undefined) {
        this.dataTimes[0] = "";
        this.dataTimes[1] = "";
      }
      if (this.form_record.orderNo == undefined) {
        this.form_record.orderNo = "";
      }
      let params = {
        page: "1",
        limit: "10",
        id: index.new_join_id,
        order_num: this.form_record.orderNo,
        types: this.rechageQd,
        start: this.dataTimes[0],
        ends: this.dataTimes[1],
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getUserRechargelist", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.userRecod = res.data.data.data;
          this.userRecodTotal = res.data.data.total;
          for (let i = 0; i < this.userRecod.length; i++) {
            this.userRecod[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 搜索流水记录
    scRecodeJl(isRus) {
      if (isRus == "1") {
        this.userRecodcurrentPage = 1;
        this.userRecodpageSize = 10;
        this.form_record.orderNo = "";
        this.rechageQd = "";
        this.form_record.dataTimes = "";
        this.dataTimes = [];
      }
      this.consumptionFormVisible = true;
      if (this.dataTimes[0] == undefined && this.dataTimes[1] == undefined) {
        this.dataTimes[0] = "";
        this.dataTimes[1] = "";
      }
      let params = {
        page: this.userRecodcurrentPage,
        limit: this.userRecodpageSize,
        id: this.recodeId,
        order_num: this.form_record.orderNo,
        types: this.rechageQd,
        start: this.dataTimes[0],
        ends: this.dataTimes[1],
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getUserRechargelist", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.userRecod = res.data.data.data;
          this.userRecodTotal = res.data.data.total;
          for (let i = 0; i < this.userRecod.length; i++) {
            this.userRecod[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 获取客户信息
    getCustomdata(ty) {
      if (!ty) {
        this.queryUser = "";
        this.currentPage = 1;
        this.pageSize = 100;
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        users: this.queryUser,
      };
      params = this.$lmjm(params);
      this.vLoading = true;
      const result = this.$http.post("/api/v1/getCustomdata", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.tableData = res.data.data.data;
          this.userTotal = res.data.data.total;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
          }
          this.vLoading = false;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  created() {
    this.getUser()
    this.getCustomdata();
    this.getCardList()

  },
  mounted() { },
};
</script>

<style scoped>
.el-input,
.el-select {
  margin-right: 1%;
}

.el-form {
  margin-bottom: 20px;
}

.pagesRecod {
  margin-bottom: 20px;
}

.carcontainer {
  display: flex;
  flex-direction: column;
}

.select_Card {
  margin-bottom: 25px;
}

.select_Card_item {
  cursor: pointer;
  margin-right: 10px;
}
</style>
