<template>
  <el-main>
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{ $t('transactionHistory.home') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('transactionHistory.depositApplication') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('transactionHistory.title') }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <!-- 搜索框 -->
            <el-form :model="form_record" :rules="form_recordRules" ref="form_recordFormRef"
              :label-position="labelPosition">
              <!-- 订单号 -->
              <el-input v-model="form_record.orderNo" prefix-icon="el-icon-tickets"
                :placeholder="$t('transactionHistory.des6')">
              </el-input>
              <!-- 充值渠道 -->
              <el-select style="width: 150px" v-model="selectVlues" :placeholder="$t('transactionHistory.des7')"
                clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <!-- 订单状态 -->
              <el-select style="width: 150px" v-model="selectVluesOrder" :placeholder="$t('transactionHistory.des8')"
                clearable>
                <el-option v-for="item in orderStatus" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <!-- 日期选择 -->
              <el-date-picker v-model="dataTimes" type="daterange" align="right" unlink-panels range-separator="-"
                :start-placeholder="$t('transactionHistory.des4')" :end-placeholder="$t('transactionHistory.des5')"
                :picker-options="pickerOptions2" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
              </el-date-picker>
              <el-button icon="el-icon-search" @click="getRachargOrder()">{{
                $t('transactionHistory.search') }}</el-button>
              <el-button icon="el-icon-refresh" @click="getRachargOrder('1')">{{
                $t('transactionHistory.flushed') }}</el-button>
              <el-button icon="el-icon-download" @click="batchExports()">
                {{ $t('transactionHistory.download') }}
              </el-button>
              <!-- <el-button icon="el-icon-sort" @click="openConvert()">
                {{ $t('transactionHistory.redemptionHistory') }}
              </el-button> -->
            </el-form>
          </div>
          <!-- 充值记录表格 -->
          <div class="user_box">
            <el-table @selection-change="handleSelectionChange" :data="Rechargedata" v-loading="vLoading"
              style="width: 100%" height="640" max-height="700" size="small">
              <!-- id- ids，用户名-name，手机号码-tel，上级名称-superior，用户状态-status，注册时间-date，余额-balance，拒付率-dishonor，操作 -->
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column label="id" prop="id" v-if="false">
              </el-table-column>
              <el-table-column :label="$t('transactionHistory.orderNumber')" prop="order_num" width="250">
              </el-table-column>
              <el-table-column :label="$t('transactionHistory.remark')" prop="remark" width="250">
                <template slot-scope="scope">
                  <span>{{ scope.row.remark }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('transactionHistory.topUpUsers')" prop="users" width="110">
              </el-table-column>
              <el-table-column :label="$t('transactionHistory.creationTime')" prop="createtime" width="150">
              </el-table-column>
              <el-table-column prop="types" :label="$t('transactionHistory.rechargeChannels')" width="150">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.types == 1" type="success">
                    {{ $t('transactionHistory.alipay') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.types == 2"> {{ $t('transactionHistory.wechat') }} </el-tag>
                  <el-tag v-else-if="scope.row.types == 3"> {{ $t('transactionHistory.bankCards') }} </el-tag>
                  <el-tag v-else-if="scope.row.types == 4" type="warning">
                    {{ $t('transactionHistory.manualTopUp') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.types == 5" type="danger">{{ $t('transactionHistory.manualDeductions')
                    }}</el-tag>
                  <el-tag v-else-if="scope.row.types == 6">{{ $t('transactionHistory.offshoreTopUp') }}</el-tag>
                  <el-tag v-else-if="scope.row.types == 7" type="danger">{{ $t('transactionHistory.cryptocurrencies')
                    }}</el-tag>
                  <el-tag v-else-if="scope.row.types == 101">{{ $t('transactionHistory.CardRecharge')
                    }}</el-tag>
                  <el-tag v-else-if="scope.row.types == 102">{{
                    $t('transactionHistory.CardTransferOut')
                  }}</el-tag>
                  <el-tag v-else-if="scope.row.types == 103">{{ $t('transactionHistory.CardOpeningFee')
                    }}</el-tag>
                  <el-tag v-else-if="scope.row.types == 104">{{
                    $t('transactionHistory.CardSalesTransfer')
                  }}</el-tag>
                  <el-tag v-else-if="scope.row.types == 106">{{ $t('transactionHistory.GatewayFailure')
                    }}</el-tag>
                  <el-tag v-else-if="scope.row.types == 107">{{ $t('transactionHistory.CrossFees')
                    }}</el-tag>
                  <el-tag v-else-if="scope.row.types == 110">{{ $t('transactionHistory.transactionFees')
                    }}</el-tag>
                  <el-tag v-else-if="scope.row.types == 111">{{ $t('transactionHistory.AdvertisingFee')
                    }}</el-tag>
                  <el-tag v-else-if="scope.row.types == 112">{{ $t('transactionHistory.RefundFees')
                    }}</el-tag>
                </template>
              </el-table-column>
              <!-- <el-table-column :label="$t('transactionHistory.amount')">
                <template slot-scope="scope">
                  <span>{{ scope.row.money }}</span>
                </template>
              </el-table-column> -->
              <el-table-column :label="$t('transactionHistory.theEstimatedAmountToBeReceived')" prop="receipt"
                width="100">
                <template slot-scope="scope">
                  <span>{{ changeMoney(scope.row.types, scope.row.receipt) }}</span>
                </template>
              </el-table-column>
              <!-- 充值币种类型 -->
              <el-table-column prop="montype" :label="$t('transactionHistory.theTypeOfDepositCurrency')" width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.montype === 1"> CNY </el-tag>
                  <el-tag v-else-if="scope.row.montype === 2"> USD </el-tag>
                </template>
              </el-table-column>
              <el-table-column :label="$t('transactionHistory.walletBalance')" prop="usdyue">
              </el-table-column>

              <!-- 充值状态 -->
              <el-table-column prop="status" :label="$t('transactionHistory.topUpStatus')" width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status === 1" type="warning">
                    {{ $t('transactionHistory.waitForPayment') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.status === 2" type="success">
                    {{ $t('transactionHistory.success') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.status === 3" type="danger">
                    {{ $t('transactionHistory.fail') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.status === 4" type="info">
                    {{ $t('transactionHistory.cancelThePayment') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.status === 5" type="danger">
                    {{ $t('transactionHistory.theTopUpExpires') }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- 操作 -->
              <el-table-column :label="$t('transactionHistory.operation')" width="200">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" v-if="scope.row.status === 1 && scope.row.types != '7'"
                    @click="subOnlineRecharge(scope.row)">
                    {{ $t('transactionHistory.repay') }}</el-button>
                  <el-button size="mini" type="danger" v-if="scope.row.status === 1" @click="closeOrder(scope.row)">
                    {{ $t('transactionHistory.cancelThePayment') }}</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[100, 500, 1000]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="this.total">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 微信支付弹窗 -->
    <el-dialog custom-class="ls" :visible.sync="wxVisible_jl" width="25%" center @close="closeTime()">
      <div v-if="hfType == 'weixin'" style="
          width: 100%;
          font-size: 28px;
          text-align: center;
          margin-bottom: 20px;
        ">
        <svg t="1681238376015" class="icon" viewBox="0 0 1144 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="2699" width="200" height="200">
          <path
            d="M436.314353 632.771765c-68.517647 36.321882-78.667294-20.389647-78.667294-20.389647l-85.835294-190.524236c-33.039059-90.533647 28.581647-40.839529 28.581647-40.839529s52.856471 38.038588 93.003294 61.229176c40.086588 23.190588 85.835294 6.806588 85.835294 6.806589l561.212235-246.362353C936.899765 80.112941 765.891765 0 572.235294 0 256.180706 0 0 213.232941 0 476.310588c0 151.311059 84.811294 285.967059 216.937412 373.248l-23.792941 130.288941s-11.625412 38.038588 28.611764 20.389647c27.437176-12.047059 97.370353-55.115294 138.992941-81.347764 65.445647 21.684706 136.734118 33.731765 211.486118 33.731764 316.024471 0 572.235294-213.232941 572.235294-476.310588 0-76.197647-21.594353-148.178824-59.843764-212.028235-178.808471 102.309647-594.733176 340.118588-648.312471 368.489412z"
            fill="#43C93E" p-id="2700"></path>
        </svg>{{ $t('transactionHistory.weChatPay') }}
      </div>
      <div v-if="hfType == 'alipay'" style="
          width: 100%;
          font-size: 28px;
          text-align: center;
          margin-bottom: 20px;
        ">
        <svg t="1685944697467" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="2557" width="200" height="200">
          <path
            d="M230.771014 576.556522c-12.614493 9.646377-25.228986 23.744928-28.93913 42.295652-5.194203 24.486957-0.742029 55.652174 22.26087 80.13913 28.93913 28.93913 72.718841 37.101449 92.011594 38.585508 51.2 3.710145 106.110145-22.26087 147.663768-50.457971 16.324638-11.130435 43.77971-34.133333 70.492754-69.750725-59.362319-30.423188-133.565217-64.556522-212.22029-61.588406-41.553623 1.484058-70.492754 9.646377-91.269566 20.776812zM983.188406 712.347826c25.971014-61.588406 40.811594-129.113043 40.811594-200.347826 0-281.971014-230.028986-512-512-512S0 230.028986 0 512s230.028986 512 512 512c170.666667 0 321.298551-83.849275 414.794203-212.22029C838.492754 768.742029 693.797101 696.023188 604.011594 652.985507c-42.295652 48.973913-105.368116 97.205797-176.602898 117.982609-44.521739 13.356522-85.333333 18.550725-126.886957 9.646377-42.295652-8.904348-72.718841-28.197101-90.527536-47.489855-8.904348-10.388406-19.292754-22.26087-27.455073-37.843479 0.742029 0.742029 0.742029 2.226087 0.742029 2.968116 0 0-4.452174-7.42029-7.420289-19.292753-1.484058-5.936232-2.968116-11.872464-3.710145-17.808696-0.742029-4.452174-0.742029-8.904348 0-12.614493-0.742029-7.42029 0-15.582609 1.484058-23.744927 4.452174-20.776812 12.614493-43.77971 35.617391-65.298551 48.973913-48.231884 115.014493-50.457971 149.147826-50.457971 50.457971 0.742029 138.017391 22.26087 212.22029 48.973913 20.776812-43.77971 34.133333-89.785507 42.295652-121.692754H304.973913v-33.391304h158.052174v-66.782609H272.324638v-34.133333h190.701449v-66.782609c0-8.904348 2.226087-16.324638 16.324638-16.324637h74.944927v83.107246h207.026087v33.391304H554.295652v66.782609H719.768116S702.701449 494.933333 651.501449 586.202899c115.014493 40.811594 277.518841 104.626087 331.686957 126.144927z m0 0"
            fill="#06B4FD" p-id="2558"></path>
        </svg>{{ $t('transactionHistory.alipay') }}
      </div>
      <div style="width: 100%; margin: 0 auto; text-align: center">
        <el-tag type="warning">{{ $t('transactionHistory.des1') }}</el-tag>
        <br />
        <br />
      </div>
      <div style="text-align: center">
        <div class="qr-code" ref="qrCodeUrl"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="wxVisible_jl = false">{{ $t('transactionHistory.close') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialog_hf_jl" width="70%">
      <iframe :src="url_jl" frameborder="0" width="100%" height="700"></iframe>
    </el-dialog>
    <!-- 转换记录弹窗 -->
    <el-dialog :visible.sync="convertModal" width="70%" top="4vh">
      <div class="grid-content bg-purple">
        <div class="user_box">
          <!-- <h5 style="font-weight: 500">转换记录</h5> -->
          <el-table :data="wallteData" height="700" style="width: 100%">
            <!-- 用户名 -->
            <el-table-column prop="users" :label="$t('transactionHistory.username')"></el-table-column>
            <!-- 订单id -->
            <el-table-column prop="order_num" :label="$t('transactionHistory.orderId')"></el-table-column>
            <!-- 兑换时间 -->
            <el-table-column prop="createtime" :label="$t('transactionHistory.redemptionTime')"></el-table-column>
            <!-- 消费金额 -->
            <el-table-column prop="cnycount" :label="$t('transactionHistory.spendingAmountCny')"></el-table-column>
            <!-- 兑换汇率  -->
            <el-table-column prop="lv" :label="$t('transactionHistory.exchangeRate')"></el-table-column>
            <!-- 兑换金额 -->
            <el-table-column prop="usdbalance" :label="$t('transactionHistory.exchangeAmountUsd')"></el-table-column>
            <!-- 兑换状态 -->
            <el-table-column prop="status" :label="$t('transactionHistory.redemptionStatus')" width="120">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status === 1" type="success">
                  {{ $t('transactionHistory.success') }}
                </el-tag>
                <el-tag v-else-if="scope.row.status === 2" type="danger">
                  {{ $t('transactionHistory.fail') }}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination align="center" @size-change="handleSizeChangeConvert"
            @current-change="handleCurrentChangeConvert" :current-page="currentPageConvert"
            :page-sizes="[10, 500, 1000]" :page-size="pageSizeConvert" layout="total, sizes, prev, pager, next, jumper"
            :total="totalConvert">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import { dataConversionUtil } from "../../plugins/excel.js";
import QRCode from "qrcodejs2";
const lodash = require("lodash");
export default {
  data() {
    return {
      dialog_hf_jl: false,
      name: "充值记录",
      total: 0,
      multipleSelection: [],
      czTypes: "",
      orderId_jl: "",
      url_jl: "",
      wxVisible_jl: false,
      zfurl: "",
      selectVlues: "",
      selectVluesOrder: "",
      dataTimes: [],
      startTime: "",
      endTime: "",
      Rechargedata: [], // 表格数据
      vLoading: false,
      form_recordRules: {}, // 表单验证
      currentPage: 1, // 当前页码
      pageSize: 100, // 每页数据显示 条数
      form_record: {
        orderNo: "",
        amout: "",
      },
      hfType: "", //
      // 充值状态
      orderStatus: [
        {
          value: "1",
          label: this.$t('transactionHistory.waitForPayment'),
        },
        {
          value: "2",
          label: this.$t('transactionHistory.successpay'),
        },
        {
          value: "3",
          label: this.$t('transactionHistory.failpay'),
        },
        {
          value: "4",
          label: this.$t('transactionHistory.cancelThePayment'),
        },
        {
          value: "5",
          label: this.$t('transactionHistory.theTopUpExpires'),
        },
      ],
      // 充值方式
      options: [
        // {
        //   value: "1",
        //   label: this.$t('transactionHistory.alipay'),
        // },
        // {
        //   value: "2",
        //   label: this.$t('transactionHistory.wechat'),
        // },
        // {
        //   value: "3",
        //   label: this.$t('transactionHistory.bankCards'),
        // },
        {
          value: "4",
          label: this.$t('transactionHistory.manualTopUp'),
        },
        {
          value: "5",
          label: this.$t('transactionHistory.manualDeductions'),
        },
        {
          value: "6",
          label: this.$t('transactionHistory.offshoreTopUp'),
        },
        {
          value: "7",
          label: this.$t('transactionHistory.cryptocurrencies'),
        },
        {
          value: "101",
          label: this.$t('transactionHistory.CardRecharge'),
        },
        {
          value: "102",
          label: this.$t('transactionHistory.CardTransferOut'),
        },
        {
          value: "103",
          label: this.$t('transactionHistory.CardOpeningFee'),
        },
        {
          value: "104",
          label: this.$t('transactionHistory.CardSalesTransfer'),
        },
        {
          value: "106",
          label: this.$t('transactionHistory.GatewayFailure'),
        },
        {
          value: "107",
          label: this.$t('transactionHistory.CrossFees'),
        },
        {
          value: "110",
          label: this.$t('transactionHistory.transactionFees'),
        },
        {
          value: "111",
          label: this.$t('transactionHistory.AdvertisingFee'),
        },
        {
          value: "112",
          label: this.$t('transactionHistory.RefundFees'),
        },
      ],
      pickerOptions2: {
        shortcuts: [
          {
            text: this.$t('transactionHistory.theLastWeek'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t('transactionHistory.theLastMonth'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t('transactionHistory.lastThreeMonths'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      convertModal: false, // 转换记录弹窗
      wallteData: [], //  转换记录数据
      currentPageConvert: "",
      totalConvert: "",
      pageSizeConvert: 10,
    };
  },
  methods: {
    changeMoney(type, money) {
      if (type == 4 || type == 6 || type == 7 || type == 102 || type == 104 || type == 112) {
        return '+' + money
      } else {
        return '-' + money
      }
    },
    // 页数
    handleSizeChangeConvert(val) {
      this.currentPageConvert = 1;
      this.pageSizeConvert = val;
      this.getwalldata();
    },
    // 当前页
    handleCurrentChangeConvert(val) {
      this.currentPageConvert = val;
      this.getwalldata();
    },
    openConvert() {
      this.getwalldata();
      this.convertModal = true;
    },
    // 兑换记录
    getwalldata() {
      let params = {
        page: this.currentPageConvert,
        limit: this.pageSizeConvert,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getwalldata", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.wallteData = res.data.data.data;
          this.totalConvert = res.data.data.total;
          for (let i = 0; i < this.wallteData.length; i++) {
            this.wallteData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      // 可依据须要在这里定义时间格式
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getRachargOrder();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getRachargOrder();
    },
    // 取消支付
    closeOrder(row) {
      let params = {
        id: row.id,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/cancelpay", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.$message.success("取消订单成功!");
            this.getRachargOrder();
          } else {
            this.$message.error("操作失败，请重试!");
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 获取充值订单
    getRachargOrder(rush) {
      if (rush == "1") {
        this.currentPage = 1;
        this.pageSize = 100;
        this.form_record.orderNo = "";
        this.selectVlues = "";
        this.selectVluesOrder = "";
        this.dataTimes = [];
      }
      if (this.dataTimes[0] == undefined && this.dataTimes[1] == undefined) {
        this.dataTimes[0] = "";
        this.dataTimes[1] = "";
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        order_num: this.form_record.orderNo,
        types: this.selectVlues,
        status: this.selectVluesOrder,
        start: this.dataTimes[0],
        ends: this.dataTimes[1],
      };

      params = this.$lmjm(params);
      this.vLoading = true
      const result = this.$http.post("/api/v1/getreRechargedata", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.total = res.data.data.total;
          this.Rechargedata = res.data.data.data;
          for (let i = 0; i < this.Rechargedata.length; i++) {
            this.Rechargedata[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
          }
          this.vLoading = false
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 重新支付
    subOnlineRecharge(row) {
      console.log("row: ", row);
      // this.czTypes = row.types
      this.zfurl = "";
      this.orderId_jl = "";
      if (row.status == "1") {
        if (
          row.order_num.indexOf("shop") != -1 ||
          row.order_num.indexOf("hy") != -1
        ) {
          this.wxVisible_jl = true;
        }
        this.orderId_jl = row.order_num;
        this.getWxPay(row);
        // this.$nextTick(() => {
        //   document.getElementById("qrcode").innerHTML = "";

        // })
      }
    },
    cleanCode() {
      this.$refs.qrCodeUrl.innerHTML = "";
    },
    // 获取支付连接
    getWxPay(row) {
      this.zfurl = "";
      let params = {
        id: row.id,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/resetpay", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code === 200) {
            this.zfurl = res.data.data.url;
            console.log("res.data.data: ", res.data.data);
            if (
              row.order_num.indexOf("shop") != -1 ||
              row.order_num.indexOf("hy") != -1
            ) {
              if (res.data.data.source_url.indexOf("weixin") != -1) {
                this.hfType = "weixin";
              } else {
                this.hfType = "alipay";
              }
              new QRCode(this.$refs.qrCodeUrl, {
                text: res.data.data.source_url, // 二维码的内容
                width: 250,
                height: 250,
                colorDark: "#000",
                colorLight: "#fff",
                correctLevel: QRCode.CorrectLevel.H,
              });
            } else {
              this.url_jl = res.data.data.source_url;
              this.dialog_hf_jl = true;
            }
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    closeTime() {
      this.cleanCode();
    },
    batchExports() {
      const tableHeader = [
        [
          "信息",
          "备注",
          "用户名",
          "创建时间",
          "事件",
          "金额",
          "币种",
          "钱包余额",
          "充值状态",
        ],
      ];
      const dataList = [];
      // 将导出数据存放在this.mumultipleSelection中 ,遍历将要导出的数据
      this.multipleSelection.forEach((item, index) => {
        // 充值状态
        let sta = "";
        switch (item.status) {
          case 1:
            sta = "等待付款";
            break;
          case 2:
            sta = "成功";
            break;
          case 3:
            sta = "失败";
            break;
          case 4:
            sta = "取消支付";
            break;
          case 5:
            sta = "充值过期";
            break;
        }
        // 充值渠道
        let ty = "";
        switch (item.types) {
          case 1:
            ty = "支付宝";
            break;
          case 2:
            ty = "微信";
            break;
          case 3:
            ty = "银行卡";
            break;
          case 4:
            ty = "人工充值";
            break;
          case 5:
            ty = "人工扣除";
            break;
          case 6:
            ty = "离岸充值";
            break;
          case 7:
            ty = "加密货币";
            break;
          case 101:
            ty = "卡充值";
            break;
          case 102:
            ty = "卡转出";
            break;
          case 103:
            ty = "开卡费";
            break;
          case 104:
            ty = "销卡转入";
            break;
          case 105:
            ty = "网关失败";
            break;
          case 106:
            ty = "跨境手续费";
            break;
          case 107:
            ty = "交易手续费";
            break;
          case 108:
            ty = "广告费支付";
            break;
          case 109:
            ty = "广告费退款";
            break;
        }
        dataList.push([
          item.order_num,
          item.remark,
          item.users,
          item.createtime,
          ty,
          item.receipt,
          item.montype == "1" ? "CNY" : "USD",
          item.usdyue,
          sta,
        ]);
      });
      console.log('dataList', dataList)
      dataConversionUtil.dataToExcel("导出列表名", tableHeader, dataList);
      this.$message.success("导出成功!");
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
  created() {
    this.getRachargOrder();
  },
  mounted() { },
};
</script>

<style scoped>
.el-select,
.el-input--prefix {
  margin-right: 1%;
}

.el-input--prefix {
  width: 15%;
}

.el-range-editor {
  margin-right: 16px;
}

.el-button {
  /* margin-right: 2%; */
}

.qrcode {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  padding: 2%;
}

.wx_content {
  padding: 3% 4%;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 0px rgb(0 0 0 / 0%) !important;
}

svg {
  width: 20px;
  height: 20px;
}
</style>
